import React, {useState} from 'react';
import * as comp from '../components_app/componets';
import * as api from '../components_app/Api';
import {useNavigate} from 'react-router-dom';
import {useAlert} from '../components_app/useAlert';

interface User {
    userId: number;
    vorname: string;
    nachname: string;
    email: string;
    rolleId: number;
    username: string;
    password: string | null;
    positionId: number;
    aktiv: boolean;
    rolle: any | null;
    position: any | null;
    logs: any | null;
    ezaInhs: any | null;
}

const LoginMain = (props: any) => {
    const {setToken} = props;
    const [userData, setUserData] = useState({user: '', pas: ''});
    const showAlert = useAlert();

    const handlChange = (e: any) => {
        setUserData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const navigate = useNavigate();
    const handlSubmit = (e: any) => {
        e.preventDefault();
        // console.log('Test');
        api.Login(userData.user, userData.pas)
            .then((response) => {
                //    console.log(response);
                setToken(response.data.token);

                localStorage.setItem('jwtToken', response.data.token);
                localStorage.setItem(
                    'user',
                    JSON.stringify(response.data.user),
                );

                navigate('/EzaManagement');
            })
            .catch((error) => {
                console.error('Error updating user', error);
                if (error?.response?.data === 'Nicht autorisiert') {
                    localStorage.setItem('jwtToken', '');
                    navigate('/login');
                }
                showAlert({
                    message: error?.response?.data
                        ? error.response.data
                        : 'Fehler',
                    severity: 'error',
                    timeout: 3000,
                });
            });
    };
    return (
        <div className='h-full w-full flex items-center justify-center '>
            <div className='flex flex-col justify-center items-center border-2 border-gray-900 bg-gray-100/10 rounded-2xl p-5'>
                <div className='text-3xl font-bold mb-4'>
                    Pöhler EZA Management
                </div>
                <form onSubmit={handlSubmit}>
                    <div className='w-full flex space-x-2'>
                        <comp.TextFieldCu
                            name='user'
                            label='Benutzername'
                            required
                            onChange={handlChange}
                            value={userData.user}
                        />
                        <comp.TextFieldCu
                            name='pas'
                            label='Password'
                            type='password'
                            required
                            onChange={handlChange}
                            value={userData.pas}
                        />
                    </div>
                    <div className='w-full flex my-3 items-center justify-center'>
                        <comp.BTN type='submit'>Login</comp.BTN>
                    </div>
                </form>
                {/* <comp.BTN onClick={api.HoleUserList}>Test</comp.BTN> */}
            </div>
        </div>
    );
};

export default LoginMain;
