import React, {useEffect, useMemo, useState} from 'react';
import * as comp from '../components_app/componets';
import * as api from '../components_app/Api';
import {DialogC} from '../components_app/Dialog';
import {TableCu} from '../components_app/Table/Table';
import {useAlert} from '../components_app/useAlert';

export const PosEdit = (props) => {
    const {posEditDialog, setPosEditDialog, data, fetchUserList} = props;
    const [select, setSelect] = useState(undefined);
    const [pos, setPos] = useState({
        positionId: null,
        bez: '',
        eurstd: '',
    });
    const showAlert = useAlert();

    useEffect(() => {
        if (select) {
            setPos({
                positionId: select.positionId,
                bez: select.bez,
                eurstd: parseFloat(select.eurstd).toFixed(2),
            });
        }
    }, [select]);

    const handleEdit = (e) => {
        e.preventDefault();
        if (!pos.positionId || !pos.bez || !pos.eurstd) {
            showAlert({
                titel: 'Fehler',
                message: 'Bitte füllen Sie alle erforderlichen Felder aus.',
                severity: 'error',
                timeout: 3000,
            });
            return;
        }
        if (select && select.positionId) {
            api.updatePos(select.positionId, pos)
                .then((response) => {
                    setPos({
                        positionId: null,
                        bez: '',
                        eurstd: '',
                    });
                    setSelect(undefined);
                    fetchUserList();
                    showAlert({
                        message: response.data,
                        severity: 'success',
                        timeout: 3000,
                    });
                })
                .catch((error) => {
                    showAlert({
                        message:
                            error.response?.data || 'Error updating position',
                        severity: 'error',
                        timeout: 3000,
                    });
                });
        }
    };

    const handleAdd = (e) => {
        e.preventDefault();
        if (!pos.bez || !pos.eurstd) {
            showAlert({
                titel: 'Fehler',
                message: 'Bitte füllen Sie alle erforderlichen Felder aus.',
                severity: 'error',
                timeout: 3000,
            });
            return;
        }
        // console.log(pos);
        api.addPos({bez: pos.bez, eurstd: pos.eurstd})
            .then((response) => {
                fetchUserList();
                showAlert({
                    message: response.data,
                    severity: 'success',
                    timeout: 3000,
                });
                setPos({
                    positionId: null,
                    bez: '',
                    eurstd: '',
                });
                setSelect(undefined);
            })
            .catch((error) => {
                //  console.log(error);
                showAlert({
                    message: error.response?.data || 'Error adding position',
                    severity: 'error',
                    timeout: 3000,
                });
            });
    };

    const handleDelete = () => {
        if (select && select.positionId) {
            api.deletePos(select.positionId)
                .then((response) => {
                    fetchUserList();
                    showAlert({
                        message: response.data,
                        severity: 'success',
                        timeout: 3000,
                    });
                    setPos({
                        positionId: '',
                        bez: '',
                        eurstd: '',
                    });
                    setSelect(undefined);
                })
                .catch((error) => {
                    showAlert({
                        message:
                            error.response?.data || 'Error deleting position',
                        severity: 'error',
                        timeout: 3000,
                    });
                });
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setPos((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeEurStd = (e) => {
        const {value} = e.target;
        setPos((prevState) => ({
            ...prevState,
            eurstd: parseFloat(value).toFixed(2),
        }));
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'bez',
                header: 'Bezeichnung',
            },
            {
                accessorKey: 'eurstd',
                header: 'Eur/Std',
            },
        ],
        [],
    );

    return (
        <>
            {data && (
                <DialogC
                    open={posEditDialog}
                    setOpen={setPosEditDialog}
                    fullWidth={true}
                    maxWidth='md'
                    Title='Positionen bearbeiten'
                >
                    <div className='flex justify-center space-x-8 mt-2'>
                        <TableCu
                            columns={columns}
                            data={data}
                            arr={data}
                            idProp='positionId'
                            setSelect={setSelect}
                            enableTopToolbar={false}
                        />

                        <div className='h-full flex-col space-y-3'>
                            <comp.TextFieldCu
                                name='bez'
                                label='Bezeichnung'
                                required
                                onChange={handleChange}
                                value={pos.bez || ''}
                                sx={{
                                    minWidth: '300px',
                                    maxWidth: '500px',
                                }}
                            />
                            <div className='flex items-center space-x-2'>
                                <comp.NumInput
                                    value={pos.eurstd || ''}
                                    label='Eur/Std'
                                    required
                                    sx={{
                                        minWidth: '5px',
                                        maxWidth: '105px',
                                    }}
                                    onChange={handleChangeEurStd}
                                />
                            </div>
                            <div className='w-full flex justify-center space-x-4'>
                                <comp.BtnGroup>
                                    <comp.BTN
                                        disabled={!select || !select.positionId}
                                        onClick={handleEdit}
                                    >
                                        Speichern
                                    </comp.BTN>
                                    <comp.BTN onClick={handleAdd}>
                                        Neu Anlegen
                                    </comp.BTN>
                                </comp.BtnGroup>
                            </div>
                            <div className='w-full flex justify-center'>
                                <comp.BTN
                                    disabled={!select || !select.positionId}
                                    color='error'
                                    type='button'
                                    onClick={handleDelete}
                                >
                                    Position löschen
                                </comp.BTN>
                            </div>
                        </div>
                    </div>
                </DialogC>
            )}
        </>
    );
};
