import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as comp from './componets';

export const DialogC = (props) => {
    const {children, open, setOpen, fullWidth, maxWidth, Title, btn} = props;
    //fullWidth = true, false
    // maxWidth = false, xs, sm, md, lg, xl

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{Title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                {btn}
                <comp.BTN variant='text' onClick={handleClose}>
                    Schließen
                </comp.BTN>
            </DialogActions>
        </Dialog>
    );
};
