import axios from 'axios';

//const API_URL = 'https://api.denis-tk.dev:2053/api';

const API_URL = 'https://poehler.denis-tk.dev/tApp/api';
//const API_URL = 'http://localhost:2053/api';

export const Login = (user, pass) => {
    //console.log('window._env_.REACT_APP_API_URL', API_URL);

    return axios.post(
        `${API_URL}/UserManagement/login`,
        {
            // Hier sind die Anmeldedaten
            Username: user,
            Password: pass,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
};

export const Register = async (user) => {
    const token = localStorage.getItem('jwtToken');
    return axios.post(`${API_URL}/UserManagement/register`, user, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const updateUser = (id, user) => {
    const token = localStorage.getItem('jwtToken');
    return axios.put(
        `${API_URL}/UserManagement/user/update`,
        {...user, userId: id},
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        },
    );
};

export const deleteUser = (id) => {
    const token = localStorage.getItem('jwtToken');
    return axios.put(
        `${API_URL}/UserManagement/user/delete`,
        {userId: id},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );
};

export const addPos = async (position) => {
    const token = localStorage.getItem('jwtToken');
    return axios.post(`${API_URL}/PositionManagement/position/add`, position, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const updatePos = (id, position) => {
    const token = localStorage.getItem('jwtToken');
    return axios.put(
        `${API_URL}/PositionManagement/position/update`,
        {...position, positionId: id},
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        },
    );
};

export const deletePos = (id) => {
    const token = localStorage.getItem('jwtToken');
    return axios.put(
        `${API_URL}/PositionManagement/position/delete`,
        {positionId: id},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );
};

export const HoleUserList = (set) => {
    const token = localStorage.getItem('jwtToken');
    return axios
        .get(`${API_URL}/UserManagement/users`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            //console.log('Benutzerliste:', response.data);
            set(response.data);
        })
        .catch((error) => {
            console.error(
                'Fehler:',
                error.response ? error.response.status : 'Network Error',
                error.response ? error.response.data : '',
            );
            set(undefined);
        });
};

export const HoleEzaData = () => {
    const token = localStorage.getItem('jwtToken');
    return axios.get(`${API_URL}/EzaManagement/dataInfo`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const HoleEzaInh = (id) => {
    const token = localStorage.getItem('jwtToken');
    return axios.get(`${API_URL}/EzaManagement/getEzaDetails/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const addEza = async (eza) => {
    const token = localStorage.getItem('jwtToken');
    return axios.post(`${API_URL}/EzaManagement/eza/add`, eza, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const updateEzaInh = async (changes) => {
    const token = localStorage.getItem('jwtToken');
    const data = {
        ...changes,
    };
    //console.log('updateEza:', data);
    return axios.post(`${API_URL}/EzaManagement/ezaInh/update`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const updateEza = (changes) => {
    const token = localStorage.getItem('jwtToken');
    return axios.post(`${API_URL}/EzaManagement/eza/update`, changes, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const updateSachbearbeiter = (changes) => {
    const token = localStorage.getItem('jwtToken');
    return axios.post(`${API_URL}/EzaManagement/sb/update`, changes, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const deleteEzaInh = (id) => {
    const token = localStorage.getItem('jwtToken');
    return axios.delete(`${API_URL}/EzaManagement/ezaInh/delete`, {
        data: {ezaInhId: id},
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteDayEzaInh = (id, dat) => {
    const token = localStorage.getItem('jwtToken');
    return axios.delete(`${API_URL}/EzaManagement/ezaInh/deleteDay`, {
        data: {EzaId: id, Dat: dat},
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const addEzaInh = async (eza) => {
    const token = localStorage.getItem('jwtToken');
    return axios.post(`${API_URL}/EzaManagement/ezaInh/add`, eza, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const HoleUserStatistikList = (setS, zeitraum) => {
    const token = localStorage.getItem('jwtToken');
    return axios
        .post(`${API_URL}/Statistik/GetStatistik`, zeitraum, {
            //body: JSON.stringify(zeitraum),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            //console.log('Benutzerliste:', response.data);
            setS(response.data);
        })
        .catch((error) => {
            console.error(
                'Fehler:',
                error.response ? error.response.status : 'Network Error',
                error.response ? error.response.data : '',
            );
            setS(undefined);
        });
};

export const DownloadRapport = async (select, sb) => {
    try {
        const response = await axios.post(
            `${API_URL}/Excel/export/eza`,
            {
                ezaId: select.ezaId,
                sachbearbeiter: sb,
            },
            {
                responseType: 'blob', // Wichtig, um die Datei als Blob zu erhalten
            },
        );

        // Erstelle eine URL für die Datei
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', select.ezaNr + '.zip'); // Dateiname für die ZIP-Datei

        // Füge den Link dem Dokument hinzu und simuliere einen Klick, um den Download zu starten
        document.body.appendChild(link);
        link.click();

        // Entferne den Link nach dem Download
        document.body.removeChild(link);
    } catch (error) {
        console.error('Fehler beim Herunterladen der Datei:', error);
    }
};

export const deleteEza = (id) => {
    const token = localStorage.getItem('jwtToken');
    return axios.delete(`${API_URL}/EzaManagement/eza/delete/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
