import React, {useEffect, useRef} from 'react';
import ApexCharts from 'apexcharts';

export const PieChartComponent = (props) => {
    const {data} = props;
    const sachbearbeiterIds = data.map((item) => item.sachbearbeiterId);
    const summenIstEur = data.map((item) => item.summeIstEur);
    const chartRef = useRef(null);

    useEffect(() => {
        const options = {
            series: summenIstEur,
            chart: {
                type: 'pie',
                height: 900,
            },
            labels: sachbearbeiterIds,
            legend: {
                show: false,
                position: 'bottom',
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    return (
                        opts.w.globals.labels[opts.seriesIndex] +
                        ': ' +
                        val.toFixed(1) +
                        '%'
                    );
                },
            },
            tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: true,
                followCursor: false,
                intersect: false,
                inverseOrder: false,
                custom: undefined,
                hideEmptySeries: true,
                fillSeriesColor: false,
                // theme: false,
                y: {
                    formatter: function (value) {
                        return value + '€';
                    },
                },
            },
            fill: {
                opacity: 1,
            },
        };

        if (chartRef.current) {
            const chart = new ApexCharts(chartRef.current, options);
            chart.render();

            return () => {
                chart.destroy();
            };
        }
    }, [sachbearbeiterIds, summenIstEur]);

    return <div id='pie-chart' ref={chartRef}></div>;
};
