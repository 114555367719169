import React, {useState} from 'react';
import * as comp from '../components_app/componets';
import * as api from '../components_app/Api';
import {DialogC} from '../components_app/Dialog';
import {useAlert} from '../components_app/useAlert';

export const AddNewUser = (props) => {
    const {newMaDialog, setNewMaDialog, data, fetchUserList} = props;
    const [newUser, setNewUser] = useState({
        username: '',
        password: '',
        email: '',
        positionId: '',
        rolleId: '',
        vorname: '',
        nachname: '',
    });
    const showAlert = useAlert();
    const user = JSON.parse(localStorage.getItem('user'));

    const handleChange = (e) => {
        setNewUser((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            !newUser.username ||
            !newUser.password ||
            !newUser.vorname ||
            !newUser.nachname ||
            !newUser.positionId ||
            !newUser.rolleId
        ) {
            showAlert({
                titel: 'Fehler',
                message: 'Bitte füllen Sie alle erforderlichen Felder aus.',
                severity: 'error',
                timeout: 3000,
            });
            return;
        }

        api.Register(newUser)
            .then((response) => {
                //  console.log('Benutzer erfolgreich registriert!', response);
                fetchUserList();
                setNewUser({
                    username: '',
                    password: '',
                    email: '',
                    positionId: '',
                    rolleId: '',
                    vorname: '',
                    nachname: '',
                });
                showAlert({
                    message: 'Benutzer erfolgreich registriert.',
                    severity: 'success',
                    timeout: 3000,
                });
            })
            .catch((error) => {
                console.error(
                    'Fehler bei der Registrierung des Benutzers',
                    error,
                );
                showAlert({
                    titel: 'Fehler',
                    message:
                        error.response?.data || 'Ein Fehler ist aufgetreten.',
                    severity: 'error',
                    timeout: 3000,
                });
            });
    };

    return (
        <DialogC
            open={newMaDialog}
            setOpen={setNewMaDialog}
            fullWidth={true}
            maxWidth='sm'
            Title='Neuen Mitarbeiter anlegen'
            btn={<comp.BTN onClick={handleSubmit}>Speichern</comp.BTN>}
        >
            <form
                onSubmit={handleSubmit}
                className='w-full flex flex-col justify-center space-y-3'
            >
                <div className='w-full flex justify-center mt-3 space-x-3'>
                    <comp.TextFieldCu
                        name='username'
                        label='Benutzername'
                        required
                        onChange={handleChange}
                        value={newUser.username}
                    />
                    <comp.TextFieldCu
                        name='password'
                        label='Password'
                        required
                        onChange={handleChange}
                        value={newUser.password}
                    />
                </div>
                <div className='w-full flex justify-center space-x-3'>
                    <comp.TextFieldCu
                        name='email'
                        label='E-mail'
                        onChange={handleChange}
                        value={newUser.email}
                    />
                </div>
                <div className='w-full flex justify-center space-x-3'>
                    <comp.TextFieldCu
                        name='vorname'
                        label='Vorname'
                        required
                        onChange={handleChange}
                        value={newUser.vorname}
                    />
                    <comp.TextFieldCu
                        name='nachname'
                        label='Nachname'
                        required
                        onChange={handleChange}
                        value={newUser.nachname}
                    />
                </div>
                <div className='w-full flex justify-center space-x-3'>
                    <comp.SelectForm
                        minWidth={223}
                        className='w-full'
                        name='positionId'
                        value={newUser.positionId}
                        label='Position'
                        onChange={handleChange}
                        items={data?.positionen}
                        idProp='positionId'
                        required={true}
                    />
                    <comp.SelectForm
                        minWidth={223}
                        className='w-full'
                        name='rolleId'
                        value={newUser.rolleId}
                        label='Rolle'
                        onChange={handleChange}
                        idProp='rolleId'
                        items={
                            user?.rolleId === 1
                                ? data?.rollen
                                : data?.rollen?.filter(
                                      (rolle) =>
                                          rolle.rolleId !== 1 &&
                                          rolle.rolleId !== 2,
                                  )
                        }
                        required={true}
                    />
                </div>
            </form>
        </DialogC>
    );
};
