import React, {useEffect, useMemo, useState} from 'react';
import {Statistik0} from '../components_app/StatistikComp';
import * as api from '../components_app/Api';
import {BasicDatePicker} from '../components_app/componets';
import * as comp from '../components_app/componets';
import {Typography} from '@mui/material';
import {PieChartComponent} from '../components_app/PieChart';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';

export const Statistik = () => {
    const [userList, setUserList] = useState([]);
    const [userStatistikList, setUserStatistikList] = useState([]);
    const navigate = useNavigate(); // Verwendung von useNavigate
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (!user?.rolleId) {
            navigate('/login');
        } else if (user.rolleId !== 1) {
            navigate('/EzaManagement');
        }
    }, []);
    const [vdat, setVdat] = useState(() => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth();

        // Set vdat to the first of the last month
        const lastMonth = new Date(currentYear, currentMonth - 1, 1);
        // Correct the month if it is January
        if (currentMonth === 0) {
            lastMonth.setFullYear(currentYear - 1, 11, 1);
        }
        return lastMonth;
    });

    const [bdat, setBdat] = useState(() => {
        // Set bdat to today's date
        const today = new Date();
        return today;
    });
    const [dates, setDates] = useState([]);

    useEffect(() => {
        api.HoleUserList(setUserList);
    }, []);

    useEffect(() => {
        // console.log('userStatistikList', userStatistikList);
        userStatistikList?.groupedResults?.length > 0 &&
            setDates(getDatesInRange(vdat, bdat));
        const today = new Date();
        // console.log('userList', today.toISOString().split('T')[0]);
    }, [userStatistikList]);
    //HoleUserStatistikList(setUserStatistikList, {zeitraum: '2021-06-01'});

    const getDatesInRange = (startDate, endDate) => {
        const start = dayjs(startDate);
        const end = dayjs(endDate);
        const dates = [];

        let current = start;

        while (current.isBefore(end) || current.isSame(end, 'day')) {
            dates.push(current.format('DD.MM.YY'));
            current = current.add(1, 'day');
        }

        return dates;
    };

    const memoizedPieChart = useMemo(() => {
        return (
            userStatistikList?.ezaListAuswertung?.length > 0 && (
                <div className='h-[700] w-[700px]'>
                    <PieChartComponent
                        data={userStatistikList?.ezaListAuswertung}
                    />
                </div>
            )
        );
    }, [userStatistikList?.ezaListAuswertung]);

    return (
        <div className='w-1920 h-1080 flex justify-center overflow-auto pt-10 space-x-5'>
            <div className='w-[800px] h-full'>
                <div className='flex w-full border-black border-2 justify-center items-end p-5 h-28 rounded-2xl space-x-2'>
                    <div>
                        <Typography variant='h7'>Von Datum:</Typography>
                        <BasicDatePicker
                            label='Von Datum'
                            value={vdat}
                            onChange={(value) => setVdat(value)}
                        />
                    </div>
                    <div>
                        <Typography variant='h7'>Bis Datum:</Typography>

                        <BasicDatePicker
                            label='Bis Datum'
                            value={bdat}
                            onChange={(value) => setBdat(value)}
                        />
                    </div>
                    <div className='mb-1'>
                        <comp.BTN
                            className='h-12 w-52'
                            onClick={() =>
                                api.HoleUserStatistikList(
                                    setUserStatistikList,
                                    {
                                        vdat: vdat,
                                        bdat: bdat,
                                    },
                                )
                            }
                        >
                            Statistik Laden
                        </comp.BTN>
                    </div>
                </div>
                {/* {userStatistikList?.ezaListAuswertung?.length > 0 && (
                    <div className='h-[700] w-[700px]'>
                        <PieChartComponent
                            data={userStatistikList?.ezaListAuswertung}
                        />
                    </div>
                )} */}
                {memoizedPieChart}
            </div>
            {userStatistikList?.groupedResults?.length > 0 &&
                userList?.users?.length > 0 && (
                    <div className='h-[850px] w-[950px] bg-slate-500/30 flex justify-center items-center border-2 border-black rounded-3xl'>
                        <div className='h-[800px] w-[900px] flex justify-center border-black border-2'>
                            <Statistik0
                                userList={userList?.users}
                                data={userStatistikList?.groupedResults}
                                dates={dates}
                            />
                        </div>
                    </div>
                )}
        </div>
    );
};
