import dayjs from 'dayjs';
import 'dayjs/locale/de';
import {
    createTheme,
    ThemeProvider,
    TextField,
    Button,
    ButtonGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
    Badge,
    styled,
    Tooltip,
} from '@mui/material';
import {Navigate} from 'react-router-dom';
import LoginMain from '../login/login';
import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CustomPopper from './CustomPopper.js';
import Holidays from 'date-holidays';
import {
    DatePicker,
    LocalizationProvider,
    PickersDay,
    StaticDatePicker,
    StaticDateTimePicker,
    pickersDayClasses,
} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {deDE} from '@mui/x-date-pickers/locales';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import 'dayjs/locale/de'; // Importiere die deutsche Lokalisierung

const localeObject = {
    name: 'de', // Name des Sprachcodes
    weekdays:
        'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split(
            '_',
        ), // Wochentage Array
    weekdaysShort: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'), // OPTIONAL, kurze Wochentage Array, verwendet die ersten drei Buchstaben, wenn nicht angegeben
    weekdaysMin: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'), // OPTIONAL, minimale Wochentage Array, verwendet die ersten zwei Buchstaben, wenn nicht angegeben
    weekStart: 0, // OPTIONAL, legt den Start der Woche fest. Wenn der Wert 1 ist, beginnt die Woche am Montag statt am Sonntag.
    yearStart: 4, // OPTIONAL, die Woche, die den 4. Januar enthält, ist die erste Woche des Jahres.
    months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split(
        '_',
    ), // Monate Array
    monthsShort: 'Jan_Feb_Mär_Apr_Mai_Jun_Jul_Aug_Sep_Okt_Nov_Dez'.split('_'), // OPTIONAL, kurze Monate Array, verwendet die ersten drei Buchstaben, wenn nicht angegeben
    ordinal: (n) => `${n}.`, // Ordinalfunktion (Zahl) => gibt Zahl + Ausgabe zurück
    formats: {
        // abgekürzte Formatoptionen zur Lokalisierung
        LTS: 'HH:mm:ss',
        LT: 'HH:mm',
        L: 'DD.MM.YYYY',
        LL: 'D. MMMM YYYY',
        LLL: 'D. MMMM YYYY HH:mm',
        LLLL: 'dddd, D. MMMM YYYY HH:mm',
        // Kleinbuchstaben/kurze, optionale Formate zur Lokalisierung
        l: 'D.M.YYYY',
        ll: 'D. MMM YYYY',
        lll: 'D. MMM YYYY HH:mm',
        llll: 'ddd, D. MMM YYYY HH:mm',
    },
    relativeTime: {
        // Relative Zeitformatzeichenfolgen, %s %d bleiben gleich
        future: 'in %s', // z.B. in 2 Stunden, %s wurde durch 2 Stunden ersetzt
        past: 'vor %s',
        s: 'ein paar Sekunden',
        m: 'eine Minute',
        mm: '%d Minuten',
        h: 'eine Stunde',
        hh: '%d Stunden', // z.B. 2 Stunden, %d wurde durch 2 ersetzt
        d: 'ein Tag',
        dd: '%d Tage',
        M: 'ein Monat',
        MM: '%d Monate',
        y: 'ein Jahr',
        yy: '%d Jahre',
    },
    meridiem: (hour, minute, isLowercase) => {
        // OPTIONAL, AM/PM
        return hour < 12 ? 'AM' : 'PM';
    },
};

dayjs.locale('de', localeObject);
dayjs.extend(utc);
dayjs.extend(timezone);

// Erstelle ein Theme mit angepassten Farben
const theme = createTheme({
    palette: {
        primary: {
            main: '#4a4a4a', // Braune Farbe für Primary
        },
        secondary: {
            main: '#19857b', // Grüne Farbe für Secondary
        },
    },
});
const themeBtn = createTheme({
    palette: {
        primary: {
            main: '#4a4a4a', // Braune Farbe für Primary
        },
        secondary: {
            main: '#BBBDBF', // Grüne Farbe für Secondary
        },
        green: {
            main: 'green', // Grüne Farbe für Secondary
            contrastText: '#ffffff', // Weiße Schriftfarbe
        },
        red: {
            main: 'red', // Grüne Farbe für Secondary
            contrastText: '#ffffff', // Weiße Schriftfarbe
        },
    },
});
export const TextFieldCu = (props) => {
    const {
        color = 'primary',
        type = '',
        label = '',
        variant = 'outlined',
        value,
        onChange,
        required,
        margin = 'none',
        name = '',
        className = '',
        fullWidth,
        sx = {},
        disabled,
        error,
        helperText,
        onBlur,
        bg_white = false,
    } = props;
    return (
        <ThemeProvider theme={theme}>
            {bg_white ? (
                <Box
                    component='form'
                    sx={{
                        '& .MuiTextField-root': {
                            background: 'white',
                        },
                    }}
                    noValidate
                    autoComplete='off'
                >
                    <TextField
                        disabled={disabled}
                        className={className}
                        name={name}
                        id={`${name}-input`}
                        color={color}
                        required={required}
                        type={type}
                        label={label}
                        value={value}
                        onChange={onChange}
                        variant={variant}
                        margin={margin}
                        fullWidth={fullWidth}
                        sx={sx}
                        error={error}
                        helperText={helperText}
                        onBlur={onBlur}
                    />
                </Box>
            ) : (
                <TextField
                    disabled={disabled}
                    className={className}
                    name={name}
                    id={`${name}-input`}
                    color={color}
                    required={required}
                    type={type}
                    label={label}
                    value={value}
                    onChange={onChange}
                    variant={variant}
                    margin={margin}
                    fullWidth={fullWidth}
                    sx={sx}
                    error={error}
                    helperText={helperText}
                    onBlur={onBlur}
                />
            )}
        </ThemeProvider>
    );
};
export const BtnGroup = (props) => {
    let {children, className, variant} = props;
    if (!variant) {
        variant = 'contained';
    }
    if (!className) {
        className = '';
    }
    return (
        <ThemeProvider theme={themeBtn}>
            <ButtonGroup
                className={className}
                variant={variant}
                aria-label='Basic button group'
            >
                {children}
            </ButtonGroup>
        </ThemeProvider>
    );
};
export const BTN = ({
    children,
    onClick = () => {},
    variant = 'contained',
    disabled = false,
    margin = 'normal',
    type = '',
    className = '',
    color = 'primary',
    startIcon = undefined,
}) => {
    return (
        <ThemeProvider theme={themeBtn}>
            <Button
                className={className}
                type={type}
                variant={variant}
                disabled={disabled}
                onClick={onClick}
                margin={margin}
                color={color}
                startIcon={startIcon}
            >
                {children}
            </Button>
        </ThemeProvider>
    );
};

export const ProtectedRoute = ({
    isAllowed,
    redirectPath = '/landing',
    children,
}) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <LoginMain />;
};

export const SelectForm = (props) => {
    const {
        minWidth,
        value,
        onChange,
        label,
        name,
        className,
        items,
        required = false,
        idProp = 'id',
    } = props;
    const [error, setError] = React.useState(false);

    const handleBlur = () => {
        if (required && !value) {
            setError(true);
        } else {
            setError(false);
        }
    };

    return (
        <ThemeProvider theme={themeBtn}>
            <FormControl
                sx={{minWidth: minWidth}}
                required={required}
                error={error}
            >
                <InputLabel id={`${name}-label`}>{label}</InputLabel>
                <Select
                    labelId={`${name}-label`}
                    id={name}
                    className={className}
                    name={name}
                    value={value}
                    label={label}
                    onChange={(e) => {
                        onChange(e);
                        if (required) {
                            setError(!e.target.value);
                        }
                    }}
                    onBlur={handleBlur}
                >
                    <MenuItem value=''>
                        <em>None</em>
                    </MenuItem>
                    {items?.map((item) => (
                        <MenuItem key={item[idProp]} value={item[idProp]}>
                            {item.bez}
                        </MenuItem>
                    ))}
                </Select>
                {error && (
                    <FormHelperText>{`${label} ist erforderlich`}</FormHelperText>
                )}
            </FormControl>
        </ThemeProvider>
    );
};
export const SelectCu = (props) => {
    const {
        value,
        onChange,
        label,
        name,
        className,
        items,
        required,
        onBlur,
        variant = 'outlined',
        minWidth = 100,
    } = props;
    const [error, setError] = React.useState(false);

    return (
        <ThemeProvider theme={themeBtn}>
            <Select
                sx={{minWidth: minWidth}}
                labelId={`${name}-label`}
                id={name}
                variant={variant}
                className={className}
                onChange={onChange}
                name={name}
                value={value}
                label={label}
                onBlur={onBlur}
            >
                <MenuItem value=''>
                    <em></em>
                </MenuItem>
                {items?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.bez}
                    </MenuItem>
                ))}
            </Select>
        </ThemeProvider>
    );
};

export const NumInput = (props) => {
    let {step, required, variant = 'outlined'} = props;
    if (!step) {
        step = '0.1';
    }

    return (
        <ThemeProvider theme={theme}>
            <TextField
                type='number'
                name={props.name}
                id={`${props.name}-input`}
                value={props.value}
                label={props.label}
                variant={variant}
                margin='none'
                required={required}
                inputProps={{
                    maxLength: 20,
                    step: step,
                }}
                sx={props.sx}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </ThemeProvider>
    );
};

export const ComboBox = (props) => {
    let {
        options,
        width,
        label,
        value,
        setValue,
        inputValue,
        setInputValue,
        required,
    } = props;
    //options { label: 'The Shawshank Redemption', year: 1994 }
    if (!options) {
        options = {label: '', key: 1};
    }
    return (
        <ThemeProvider theme={theme}>
            <Autocomplete
                //disablePortal
                freeSolo
                id={'combo-box-' + label}
                options={options}
                sx={{width: width}}
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField required={required} {...params} label={label} />
                )}
                disablePortal={false}
                PopperComponent={CustomPopper}
            />
        </ThemeProvider>
    );
};

export const MultiLine = (props) => {
    const {rows = 10} = props;
    return (
        <ThemeProvider theme={theme}>
            <Box
                component='form'
                sx={{
                    '& .MuiTextField-root': {
                        width: '70ch',
                        mx: 2,
                        background: 'white',
                    },
                }}
                noValidate
                autoComplete='off'
            >
                <TextField
                    id={props.label + 'standard-multiline-flexible'}
                    label={props.label}
                    value={props.value}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    multiline
                    rows={rows}
                    variant='outlined'
                />
            </Box>
        </ThemeProvider>
    );
};

export const RadioGroupCu = (props) => {
    const {options, selectedValue, setSelectedValue} = props;
    return (
        <ThemeProvider theme={theme}>
            <FormControl>
                <RadioGroup
                    aria-labelledby='demo-radio-buttons-group-label'
                    value={selectedValue} // Aktueller Wert der RadioGroup
                    onChange={setSelectedValue} // Event-Handler bei Änderungen
                    name='radio-buttons-group'
                >
                    <Box
                        sx={{
                            mx: 1,
                            display: 'flex',
                            flexDirection: 'row', // Ändern Sie dies in 'column', wenn Sie die Buttons untereinander anordnen möchten
                            flexWrap: 'wrap', // Ermöglicht das Umbrechen der Buttons bei Bedarf
                            gap: 1, // Abstand zwischen den Elementen
                            Select: 'userSelect',
                        }}
                    >
                        {options.map((item) => (
                            <FormControlLabel
                                sx={{
                                    userSelect: 'none',
                                }}
                                value={item.sachbearbeiterId}
                                key={item.sachbearbeiterId}
                                control={<Radio key={item.sachbearbeiterId} />}
                                label={item.bez}
                            />
                        ))}
                    </Box>
                </RadioGroup>
            </FormControl>
        </ThemeProvider>
    );
};

const getHolidaysForYear = (year) => {
    const hd = new Holidays('DE', 'BW'); // DE = Deutschland, BW = Baden-Württemberg
    const holidays = hd.getHolidays(year);
    return holidays.map((holiday) =>
        dayjs(holiday.date).tz('Europe/Berlin').format('YYYY-MM-DD'),
    );
};

export const DatePickerCu0 = (props) => {
    const {value, setValue} = props;
    const [highlightedDays, setHighlightedDays] = useState([]);

    useEffect(() => {
        const year = dayjs().year();
        const holidays = getHolidaysForYear(year);
        setHighlightedDays(holidays);
    }, []);

    // useEffect(() => {
    //     console.log('value', value);
    // }, [value]);

    const HighlightedDay = (props) => {
        const {day, outsideCurrentMonth, ...other} = props;
        const dayFormatted = day.format('YYYY-MM-DD');
        const isSelected =
            !outsideCurrentMonth && highlightedDays.includes(dayFormatted);
        const isWeekend = day.day() === 6 || day.day() === 0; // 6 = Samstag, 0 = Sonntag

        return (
            <Badge key={day.toString()} overlap='circular'>
                <PickersDay
                    {...other}
                    day={day}
                    outsideCurrentMonth={outsideCurrentMonth}
                    sx={{
                        ...(isSelected && {
                            color: '#edd05c', // Rot für Wochenenden
                        }),
                        ...(isWeekend && {
                            color: 'red', // Rot für Wochenenden
                        }),
                    }}
                />
            </Badge>
        );
    };
    const formatDayOfWeek = (date) => {
        // Format the day of week correctly
        return date.format('dd').charAt(0).toUpperCase();
    };
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <StaticDatePicker
                        orientation='portrait'
                        displayStaticWrapperAs='desktop'
                        value={dayjs(value, 'YYYY.MM.DD')}
                        onChange={(newValue) => {
                            setValue(dayjs(newValue).format('YYYY.MM.DD'));
                        }}
                        slots={{
                            day: HighlightedDay,
                        }}
                        displayWeekNumber={true}
                        dayOfWeekFormatter={formatDayOfWeek}
                        showDaysOutsideCurrentMonth
                    />
                </Box>
            </LocalizationProvider>
        </ThemeProvider>
    );
};
export const DatePickerCu = (props) => {
    const {value, setValue, onDateSelect, disabledDays} = props;
    const [highlightedDays, setHighlightedDays] = useState([]);

    useEffect(() => {
        const year = dayjs().year();
        const holidays = getHolidaysForYear(year);
        setHighlightedDays(holidays);
    }, []);

    const HighlightedDay = (props) => {
        const {day, outsideCurrentMonth, ...other} = props;
        const dayFormatted = day.format('YYYY-MM-DD');
        const isSelected =
            !outsideCurrentMonth && highlightedDays.includes(dayFormatted);
        const isDisabled =
            !outsideCurrentMonth &&
            disabledDays.includes(day.format('YYYY.MM.DD'));
        const isWeekend = day.day() === 6 || day.day() === 0; // 6 = Samstag, 0 = Sonntag
        const tooltipTitle = isSelected
            ? 'Feiertag'
            : isWeekend
            ? 'Wochenende'
            : isDisabled
            ? 'Ist in der Liste'
            : '';

        return (
            <Tooltip title={tooltipTitle}>
                <Badge key={day.toString()} overlap='circular'>
                    <PickersDay
                        {...other}
                        day={day}
                        disabled={isDisabled}
                        outsideCurrentMonth={outsideCurrentMonth}
                        sx={{
                            ...(isSelected && {
                                color: '#edd05c', // Rot für Wochenenden
                            }),
                            ...(isWeekend && {
                                color: 'red', // Rot für Wochenenden
                            }),
                        }}
                    />
                </Badge>
            </Tooltip>
        );
    };
    const formatDayOfWeek = (date) => {
        // Format the day of week correctly
        return date.format('dd').charAt(0).toUpperCase();
    };

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <StaticDatePicker
                        orientation='portrait'
                        displayStaticWrapperAs='desktop'
                        //value={dayjs(value, 'YYYY.MM.DD')}
                        onChange={(newValue) => {
                            const formattedDate =
                                dayjs(newValue).format('YYYY.MM.DD');
                            const dayOfWeek = dayjs(newValue).format('dddd');
                            setValue(formattedDate);
                            onDateSelect(formattedDate, dayOfWeek);
                        }}
                        slots={{
                            day: HighlightedDay,
                        }}
                        displayWeekNumber={true}
                        dayOfWeekFormatter={formatDayOfWeek}
                        showDaysOutsideCurrentMonth
                        views={['day']}
                        //  shouldDisableDate={shouldDisableDate} // Hier die Funktion hinzufügen
                    />
                </Box>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export const BasicDatePicker = (props) => {
    const {name, value, onChange} = props;
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                <DatePicker
                    label={name}
                    value={dayjs(value)}
                    onChange={onChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            value={
                                params.inputProps.value
                                    ? dayjs(params.inputProps.value).format(
                                          'DD.MM.YYYY',
                                      )
                                    : ''
                            }
                            onChange={params.inputProps.onChange}
                        />
                    )}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
};
