import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import * as comp from '../components_app/componets';
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate} from 'react-router-dom'; // Korrekte Verwendung von useNavigate
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';

export const TemporaryDrawer = (props) => {
    const {open, setOpen} = props;
    const navigate = useNavigate(); // Verwendung von useNavigate
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (!user?.rolleId) {
            navigate('/login');
        }
    }, []);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleMenuClick = (route) => {
        navigate(route);
        setOpen(false); // Schließt den Drawer nach der Navigation
    };

    const Admin = {
        m1: [
            {
                text: 'Eza-Management',
                icon: (
                    <AssignmentTurnedInIcon
                        className={!open ? 'text-white' : 'text-black'}
                    />
                ),
                route: '/EzaManagement',
            },
            {
                text: 'Statistik',
                icon: (
                    <DonutSmallIcon
                        className={!open ? 'text-white' : 'text-black'}
                    />
                ),
                route: '/Statistik',
            },
        ],
        m2: [
            {
                text: 'User-Management',
                icon: (
                    <ManageAccountsIcon
                        className={!open ? 'text-white' : 'text-black'}
                    />
                ),
                route: '/UserManagement',
            },
            {
                text: 'Abmelden',
                icon: (
                    <LogoutIcon
                        className={!open ? 'text-white' : 'text-black'}
                    />
                ),
                route: '/logout',
            },
        ],
    };

    const Meister = {
        m1: [
            {
                text: 'Eza-Management',
                icon: (
                    <AssignmentTurnedInIcon
                        className={!open ? 'text-white' : 'text-black'}
                    />
                ),
                route: '/EzaManagement',
            },
        ],
        m2: [
            {
                text: 'User-Management',
                icon: (
                    <ManageAccountsIcon
                        className={!open ? 'text-white' : 'text-black'}
                    />
                ),
                route: '/UserManagement',
            },
            {
                text: 'Abmelden',
                icon: (
                    <LogoutIcon
                        className={!open ? 'text-white' : 'text-black'}
                    />
                ),
                route: '/logout',
            },
        ],
    };
    const Normal = {
        m1: [
            {
                text: 'Eza-Management',
                icon: (
                    <AssignmentTurnedInIcon
                        className={!open ? 'text-white' : 'text-black'}
                    />
                ),
                route: '/EzaManagement',
            },
        ],
        m2: [
            {
                text: 'Abmelden',
                icon: (
                    <LogoutIcon
                        className={!open ? 'text-white' : 'text-black'}
                    />
                ),
                route: '/logout',
            },
        ],
    };

    const Menu =
        user?.rolleId === 1 ? Admin : user?.rolleId === 2 ? Meister : Normal;
    const DrawerList = (
        <div className='w-full h-full overflow-hidden'>
            <List>
                <ListItem key={'menuBtn1'} disablePadding className='h-12'>
                    <ListItemButton onClick={() => setOpen(!open)}>
                        <ListItemIcon>
                            <MenuIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Menu'} />
                    </ListItemButton>
                </ListItem>
                {Menu?.m1?.map((item, index) => (
                    <ListItem key={item.text} disablePadding className='h-12'>
                        <ListItemButton
                            onClick={() => handleMenuClick(item.route)}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {Menu?.m2?.map((item, index) => (
                    <ListItem key={item.text} disablePadding className='h-12'>
                        <ListItemButton
                            onClick={() => handleMenuClick(item.route)}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <div className='w-18 h-full overflow-hidden'>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
            <List>
                <ListItem key={'menuBtn1'} disablePadding className='h-12'>
                    <ListItemButton onClick={() => setOpen(!open)}>
                        <ListItemIcon>
                            <MenuIcon className='text-white' />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
                {Menu?.m1?.map((item, index) => (
                    <ListItem key={item.text} disablePadding className='h-12'>
                        <ListItemButton
                            onClick={() => handleMenuClick(item.route)}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {Menu?.m2?.map((item, index) => (
                    <ListItem key={item.text} disablePadding className='h-12'>
                        <ListItemButton
                            onClick={() => handleMenuClick(item.route)}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};
