// src/App.js
import React from 'react';
// import './stat.css';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';

const data = [
    {
        name: 'Max Mustermann',
        '06.01.24': 8,
        '06.02': 7,
        '06.03': 6,
        '06.04': 8,
        '06.05': 7,
        '06.06': 6,
        '06.07': 8,
        '06.08': 7,
    },
    {
        name: 'Erika Mustermann',
        '06.01.24': 7,
        '06.02': 8,
        '06.03': 7,
        '06.04': 7,
        '06.05': 8,
        '06.06': 7,
        '06.07': 7,
        '06.08': 8,
    },
    {
        name: 'Hans Müller',
        '06.01.24': '6.50',
        '06.02': 5,
        '06.03': 9,
        '06.04': 10,
        '06.05': 11,
        '06.06': 8,
        '06.07': 6,
        '06.08': 5,
    },
    {
        name: 'Hans Müller2',
        '06.01.24': '6.50',
        '06.02': 5,
        '06.03': 9,
        '06.04': 10,
        '06.05': 11,
        '06.06': 8,
        '06.07': 6,
        '06.08': 5,
    },
    {
        name: 'Hans Müller3',
        '06.01.24': '6.50',
        '06.02': 5,
        '06.03': 9,
        '06.04': 10,
        '06.05': 11,
        '06.06': 8,
        '06.07': 6,
        '06.08': 5,
    },
    {
        name: 'Hans Müller4',
        '06.01.24': '6.50',
        '06.02': 5,
        '06.03': 9,
        '06.04': 10,
        '06.05': 11,
        '06.06': 8,
        '06.07': 6,
        '06.08': 5,
    },
    {
        name: 'Max Mustermann',
        '06.01.24': 8,
        '06.02': 7,
        '06.03': 6,
        '06.04': 8,
        '06.05': 7,
        '06.06': 6,
        '06.07': 8,
        '06.08': 7,
    },
    {
        name: 'Erika Mustermann',
        '06.01.24': 7,
        '06.02': 8,
        '06.03': 7,
        '06.04': 7,
        '06.05': 8,
        '06.06': 7,
        '06.07': 7,
        '06.08': 8,
    },
    {
        name: 'Hans Müller',
        '06.01.24': '6.50',
        '06.02': 5,
        '06.03': 9,
        '06.04': 10,
        '06.05': 11,
        '06.06': 8,
        '06.07': 6,
        '06.08': 5,
    },
    {
        name: 'Hans Müller2',
        '06.01.24': '6.50',
        '06.02': 5,
        '06.03': 9,
        '06.04': 10,
        '06.05': 11,
        '06.06': 8,
        '06.07': 6,
        '06.08': 5,
    },
    {
        name: 'Hans Müller3',
        '06.01.24': '6.50',
        '06.02': 5,
        '06.03': 9,
        '06.04': 10,
        '06.05': 11,
        '06.06': 8,
        '06.07': 6,
        '06.08': 5,
    },
    {
        name: 'Hans Müller4',
        '06.01.24': '6.50',
        '06.02': 5,
        '06.03': 9,
        '06.04': 10,
        '06.05': 11,
        '06.06': 8,
        '06.07': 6,
        '06.08': 5,
    },
    {
        name: 'Max Mustermann',
        '06.01.24': 8,
        '06.02': 7,
        '06.03': 6,
        '06.04': 8,
        '06.05': 7,
        '06.06': 6,
        '06.07': 8,
        '06.08': 7,
    },
    {
        name: 'Erika Mustermann',
        '06.01.24': 7,
        '06.02': 8,
        '06.03': 7,
        '06.04': 7,
        '06.05': 8,
        '06.06': 7,
        '06.07': 7,
        '06.08': 8,
    },
    {
        name: 'Hans Müller',
        '06.01.24': '6.50',
        '06.02': 5,
        '06.03': 9,
        '06.04': 10,
        '06.05': 11,
        '06.06': 8,
        '06.07': 6,
        '06.08': 5,
    },
    {
        name: 'Hans Müller2',
        '06.01.24': '6.50',
        '06.02': 5,
        '06.03': 9,
        '06.04': 10,
        '06.05': 11,
        '06.06': 8,
        '06.07': 6,
        '06.08': 5,
    },
    {
        name: 'Hans Müller3',
        '06.01.24': '6.50',
        '06.02': 5,
        '06.03': 9,
        '06.04': 10,
        '06.05': 11,
        '06.06': 8,
        '06.07': 6,
        '06.08': 5,
    },
    {
        name: 'Hans Müller4',
        '06.01.24': '6.50',
        '06.02': 5,
        '06.03': 9,
        '06.04': 10,
        '06.05': 11,
        '06.06': 8,
        '06.07': 6,
        '06.08': 5,
    },
];

const dates = [
    '06.01.24',
    '06.02.24',
    '06.03.24',
    '06.04.24',
    '06.05.24',
    '06.06.24',
    '06.07.24',
];
export const Statistik0 = (props) => {
    const {data, dates, userList} = props;

    const st1 =
        'border-r border-gray-300 text-center align-middle font-extrabold bg-red-500/30';
    const st2 =
        'border-r border-gray-300 text-center align-middle font-extrabold bg-green-500/30';
    const st3 =
        'border-r border-gray-300 text-center align-middle font-extrabold bg-green-600/30';
    const st4 =
        'border-r border-gray-300 text-center align-middle font-extrabold bg-orange-500/30';
    const st5 =
        'border-r border-gray-300 text-center align-middle font-extrabold bg-red-700/60';
    const getUserName = (userId) => {
        const user = userList.find((user) => user.userId === userId);

        return user ? user.vorname + ' ' + user.nachname : 'Unbekannt';
    };

    const getUserAktive = (userId) => {
        const user = userList.find((user) => user.userId === userId);

        return user.aktiv;
    };

    return (
        <div className='h-full w-full container overflow-auto select-none'>
            <Table>
                <TableHead className='sticky top-0 bg-white z-10 border-r  border-b-2 border-black'>
                    <TableRow>
                        <TableCell className='sticky top-0 bg-white z-10 border-r border-gray-300'>
                            Mitarbeiter
                        </TableCell>
                        {dates.map((date) => (
                            <TableCell
                                key={date}
                                className='sticky top-0 bg-gray-200 z-10'
                            >
                                {date}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(
                        (row, index) =>
                            getUserAktive(row.UserId) && (
                                <TableRow
                                    key={getUserName(row.UserId) + index}
                                    className='border-b-2 border-black'
                                >
                                    <TableCell
                                        key={getUserName(row.UserId) + index}
                                        className='sticky left-0 bg-white z-10 border-r border-gray-300'
                                    >
                                        {getUserName(row.UserId)}
                                    </TableCell>
                                    {dates.map((date) => (
                                        <TableCell
                                            key={date}
                                            className={
                                                row[date] === undefined
                                                    ? st5
                                                    : row[date] == 0
                                                    ? st5
                                                    : row[date] < 8
                                                    ? st1
                                                    : row[date] <= 9
                                                    ? st2
                                                    : row[date] <= 10
                                                    ? st3
                                                    : st4
                                            }
                                        >
                                            <Typography
                                                variant='h6'
                                                className='font-extrabold text-center align-middle'
                                            >
                                                {row[date] ? row[date] : 0}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ),
                    )}
                </TableBody>
            </Table>
        </div>
    );
};
