import React, {createContext, useContext, useState} from 'react';
import {Alert} from '@mui/material';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({children}) => {
    const [alert, setAlert] = useState({
        visible: false,
        message: '',
        titel: '',
        severity: 'success',
        variant: 'filled', // Standardwert für variant gesetzt
        timeout: 6000,
    });

    const showAlert = ({
        message,
        titel = '',
        severity = 'success',
        variant = 'filled',
        timeout = 6000,
    }) => {
        if (typeof message !== 'string') {
            message = 'Fehler: Message falsches Format';
        }

        setAlert({visible: true, message, titel, severity, variant, timeout});
        setTimeout(() => {
            setAlert((prev) => ({...prev, visible: false}));
        }, timeout);
    };

    return (
        <AlertContext.Provider value={showAlert}>
            {children}
            {alert.visible && (
                <Alert
                    variant={alert.variant}
                    severity={alert.severity}
                    sx={{
                        position: 'fixed',
                        bottom: 16,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 9999,
                    }}
                >
                    {alert.message}
                </Alert>
            )}
        </AlertContext.Provider>
    );
};
