export const MRT_Localization_DE = {
    actions: 'Aktionen',
    and: 'und',
    cancel: 'Abbrechen',
    changeFilterMode: 'Filtermodus ändern',
    changeSearchMode: 'Suchmodus ändern',
    clearFilter: 'Filter zurücksetzen',
    clearSearch: 'Suche zurücksetzen',
    clearSelection: 'Auswahl zurücksetzen',
    clearSort: 'Sortierung zurücksetzen',
    clickToCopy: 'Kopieren',
    copy: 'Kopieren',
    columnActions: 'Spalten-Aktionen',
    copiedToClipboard: 'In die Zwischenablage kopiert',
    collapse: 'Einklappen',
    collapseAll: 'Alle einklappen',
    dropToGroupBy: 'Ablegen zum Gruppieren nach {column}',
    edit: 'Editieren',
    expand: 'Erweitern',
    expandAll: 'Alle erweitern',
    filterArrIncludes: 'Beinhaltet',
    filterArrIncludesAll: 'Beinhaltet alles',
    filterArrIncludesSome: 'Beinhaltet',
    filterBetween: 'Zwischen',
    filterBetweenInclusive: 'Zwischen (inklusive)',
    filterByColumn: 'Nach {column} filtern',
    filterContains: 'Enthält',
    filterEmpty: 'Leer',
    filterEndsWith: 'Endet mit',
    filterEquals: 'Gleich',
    filterEqualsString: 'Gleich',
    filterFuzzy: 'Fuzzy',
    filterGreaterThan: 'Größer als',
    filterGreaterThanOrEqualTo: 'Größer als oder gleich',
    filterInNumberRange: 'Zwischen',
    filterIncludesString: 'Enthält',
    filterIncludesStringSensitive: 'Enthält',
    filterLessThan: 'Kleiner als',
    filterLessThanOrEqualTo: 'Kleiner als oder gleich',
    filterMode: 'Filtermodus: {filterType}',
    filterNotEmpty: 'Nicht leer',
    filterNotEquals: 'Nicht gleich',
    filterStartsWith: 'Beginnt mit',
    filterWeakEquals: 'Gleich',
    filteringByColumn: 'Filtern nach {column} - {filterType} {filterValue}',
    goToFirstPage: 'Gehe zur ersten Seite',
    goToLastPage: 'Gehe zur letzten Seite',
    goToNextPage: 'Gehe zur nächsten Seite',
    goToPreviousPage: 'Gehe zur vorherigen Seite',
    grab: 'Greifen',
    groupByColumn: 'Gruppieren nach {column}',
    groupedBy: 'Gruppiert nach ',
    hideAll: 'Alle ausblenden',
    hideColumn: '{column} ausblenden',
    max: 'Max',
    min: 'Min',
    move: 'Ziehen',
    noRecordsToDisplay: 'Keine Ergebnisse zum Anzeigen gefunden',
    noResultsFound: 'Keine Ergebnisse gefunden',
    of: 'von',
    or: 'oder',
    pin: 'Anheften',
    pinToLeft: 'Links anheften',
    pinToRight: 'Rechts anheften',
    resetColumnSize: 'Spaltengröße zurücksetzen',
    resetOrder: 'Reihenfolge zurücksetzen',
    rowActions: 'Zeilen-Aktionen',
    rowNumber: '#',
    rowNumbers: 'Zeilennumern',
    rowsPerPage: 'Zeilen pro Seite',
    save: 'Speichern',
    search: 'Suche',
    selectedCountOfRowCountRowsSelected:
        '{selectedCount} von {rowCount} Zeile(n) ausgewählt',
    select: 'Auswählen',
    showAll: 'Alle anzeigen',
    showAllColumns: 'Alle Spalten anzeigen',
    showHideColumns: 'Spalten ein-/ausblenden',
    showHideFilters: 'Filter ein-/ausblenden',
    showHideSearch: 'Suche ein-/ausblenden',
    sortByColumnAsc: '{column} aufsteigend sortieren',
    sortByColumnDesc: '{column} absteigend sortieren',
    sortedByColumnAsc: '{column} aufsteigend sortiert',
    sortedByColumnDesc: '{column} absteigend sortiert',
    thenBy: ', dann nach ',
    toggleDensity: 'Zeilendichte wechseln',
    toggleFullScreen: 'Vollbild (de-)aktivieren',
    toggleSelectAll: 'Alle auswählen umschalten',
    toggleSelectRow: 'Zeile auswählen umschalten',
    toggleVisibility: 'Sichtbarkeit wechseln',
    ungroupByColumn: 'Gruppierung nach {column} aufheben',
    unpin: 'Loslösen',
    unpinAll: 'Alle loslösen',
};
