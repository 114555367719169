import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useUser} from '../Assets/UserProvider';

const Logout = ({setToken}) => {
    const navigate = useNavigate();
    const {setUser} = useUser();
    useEffect(() => {
        localStorage.setItem('jwtToken', '');
        localStorage.setItem('user', JSON.stringify({rolleId: null}));
        setToken('');
        setUser(null);
        navigate('/login'); // Weiterleiten zur Login-Seite
    }, [navigate, setToken]);

    return null;
};

export default Logout;
