import './App.css';
import './styles.css'; // Import your styles

import React, {useEffect, useState} from 'react';
import {
    HashRouter as Router,
    Route,
    Routes,
    useLocation,
} from 'react-router-dom';

import UserManagement from './Admin/userManagement';
import {AlertProvider} from './components_app/useAlert';
import LoginMain from './login/login';
import {TemporaryDrawer} from './menu/Menu';
import {EzaManagement} from './User/EZA_Management';
import Logout from './login/logout';
import {Statistik} from './Admin/statistik';

function App() {
    const [first, setFirst] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('jwtToken') || '');

    // useEffect(() => {
    //     console.log('Token: ', token);
    // }, [token]);

    return (
        <div className='w-screen h-screen min-w-[1920px] min-h-[1080px] bg-gray-200 overflow-hidden  flex'>
            <AlertProvider>
                <Router>
                    <div className='h-full w-16 mr-2 flex grow bg-gray-600'>
                        <DrawerWithCondition
                            first={first}
                            setFirst={setFirst}
                        />
                    </div>
                    <div className='w-full h-full'>
                        <Routes>
                            <Route
                                path='/login'
                                element={<LoginMain setToken={setToken} />}
                            />
                            <Route
                                path='/UserManagement'
                                element={<UserManagement />}
                            />
                            <Route
                                path='/'
                                element={<LoginMain setToken={setToken} />}
                            />
                            <Route
                                path='/EzaManagement'
                                element={<EzaManagement />}
                            />
                            <Route path='/Statistik' element={<Statistik />} />
                            <Route
                                path='/logout'
                                element={<Logout setToken={setToken} />}
                            />
                            <Route
                                path='*'
                                element={
                                    <div className='flex h-full w-full justify-center items-center font-bold text-3xl'>
                                        Hoppla! Unsere Kaffeepause ist schuld:
                                        404 – Keine Spur von dieser Seite.
                                    </div>
                                }
                            />
                        </Routes>
                    </div>
                </Router>
            </AlertProvider>
        </div>
    );
}

const DrawerWithCondition: React.FC<{
    first: boolean;
    setFirst: (open: boolean) => void;
}> = ({first, setFirst}) => {
    const location = useLocation();

    // Überprüfen, ob der aktuelle Pfad nicht '/login' ist
    if (location.pathname !== '/login' && location.pathname !== '/') {
        return <TemporaryDrawer open={first} setOpen={setFirst} />;
    }
    return null;
};

export default App;
