import React, {useEffect, useState} from 'react';
import * as comp from '../components_app/componets';
import * as api from '../components_app/Api';
import {useAlert} from '../components_app/useAlert';

export const EditUser = (props) => {
    const {data, select, fetchUserList} = props;
    const [selectedUser, setSelectedUser] = useState({
        username: select.username,
        password: select.password,
        email: select.email,
        positionId: select.positionId,
        rolleId: select.rolleId,
        vorname: select.vorname,
        nachname: select.nachname,
    });
    const showAlert = useAlert();
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        setSelectedUser({
            username: select.username,
            password: select.password,
            email: select.email,
            positionId: select.positionId,
            rolleId: select.rolleId,
            vorname: select.vorname,
            nachname: select.nachname,
        });
    }, [select]);

    const handleChange = (e) => {
        setSelectedUser((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleEdit = (e) => {
        e.preventDefault();
        api.updateUser(select.userId, selectedUser)
            .then((response) => {
                //  console.log('User updated successfully!', response);
                fetchUserList();
                showAlert({
                    message: response.data,
                    severity: 'success',
                    timeout: 3000,
                });
            })
            .catch((error) => {
                // console.error('Error updating user', error);
                showAlert({
                    message: error.response.data,
                    severity: 'error',
                    timeout: 3000,
                });
            });
    };

    const handleDelete = () => {
        api.deleteUser(select.userId)
            .then((response) => {
                //  console.log('User deleted successfully!', response);
                fetchUserList();
                showAlert({
                    message: response.data,
                    severity: 'success',
                    timeout: 3000,
                });
            })
            .catch((error) => {
                // console.error('Error deleting user', error);
                showAlert({
                    message: error.response.data,
                    severity: 'error',
                    timeout: 3000,
                });
            });
    };

    return (
        <div className='w-full border-2 p-3'>
            <form className='w-full flex flex-col m-2' onSubmit={handleEdit}>
                <div className='w-full flex space-x-4 items-center'>
                    <comp.TextFieldCu
                        name='username'
                        label='Benutzer'
                        required
                        onChange={handleChange}
                        value={selectedUser.username}
                        disabled={true}
                    />
                    <comp.TextFieldCu
                        name='password'
                        label='Password'
                        onChange={handleChange}
                        value={selectedUser.password}
                    />
                    <comp.SelectForm
                        minWidth={223}
                        className='w-full'
                        name='positionId'
                        value={selectedUser.positionId}
                        label='Position'
                        onChange={handleChange}
                        items={data?.positionen}
                        idProp='positionId'
                    />
                    <comp.SelectForm
                        minWidth={223}
                        className='w-full'
                        name='rolleId'
                        value={selectedUser.rolleId}
                        label='Rolle'
                        onChange={handleChange}
                        items={
                            user.rolleId === 1
                                ? data?.rollen
                                : data?.rollen.filter(
                                      (rolle) =>
                                          rolle.rolleId !== 1 &&
                                          rolle.rolleId !== 2,
                                  )
                        }
                        idProp='rolleId'
                    />
                    <comp.BTN type='submit'>Speichern</comp.BTN>
                    <comp.BTN
                        color='error'
                        type='button'
                        onClick={handleDelete}
                    >
                        Benutzer löschen
                    </comp.BTN>
                </div>
            </form>
            <div className='space-x-3'></div>
        </div>
    );
};
