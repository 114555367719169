import React, {useEffect, useMemo, useState} from 'react';
import * as comp from '../components_app/componets';
import * as api from '../components_app/Api';
import {TableCu} from '../components_app/Table/Table';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditNoteIcon from '@mui/icons-material/EditNote';
import {AddNewUser} from './newUser';
import {EditUser} from './editUser';
import {PosEdit} from './positionEdit';
import {useNavigate} from 'react-router-dom';

const UserManagement = (props) => {
    const [data, setData] = useState({users: {}});
    const [tblData, setTblData] = useState([]);
    const [newMaDialog, setNewMaDialog] = useState(false);
    const [select, setSelect] = useState(undefined);
    const [posEditDialog, setPosEditDialog] = useState(false);
    const navigate = useNavigate(); // Verwendung von useNavigate
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (!user?.rolleId) {
            navigate('/login');
        }
    }, [user]);

    useEffect(() => {
        api.HoleUserList(setData);
    }, []);

    useEffect(() => {
        if (data?.users && data.users.length > 0) {
            const tmp_data = data.users
                .filter((user) => user.aktiv)
                .map((user) => ({
                    ...user,
                    posBez: getBez(
                        user.positionId,
                        data.positionen,
                        'positionId',
                    ),
                    rolleBez: getBez(user.rolleId, data.rollen, 'rolleId'),
                }));

            setTblData(
                user.rolleId === 1
                    ? tmp_data
                    : tmp_data.filter(
                          (user) => user.rolleId !== 1 && user.rolleId !== 2,
                      ),
            );
        }
    }, [data]);

    const fetchUserList = () => {
        api.HoleUserList(setData);
        setNewMaDialog(false);
    };

    const getBez = (bezId, data, ObjName) => {
        if (!data) {
            return 'Unbekannt';
        }
        const bez = data.find((pos) => pos[ObjName] === bezId);
        return bez ? bez.bez : 'Unbekannt';
    };

    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorKey: 'userId',
                header: 'ID',
            },
            {
                accessorKey: 'vorname',
                header: 'Vorname',
            },
            {
                accessorKey: 'nachname',
                header: 'Nachname',
            },
            {
                accessorKey: 'username',
                header: 'Username',
            },
            {
                accessorKey: 'posBez',
                header: 'Position',
            },
            {
                accessorKey: 'rolleBez',
                header: 'Rolle',
            },
        ],
        [],
        //end
    );

    return (
        <div className='h-full w-full flex-row items-center '>
            <div className='w-full max-h-1/2 border-b-2 border-gray-700/30 overflow-auto'>
                {data && columns && tblData.length > 0 && (
                    <TableCu
                        columns={columns}
                        data={tblData}
                        arr={data}
                        setSelect={setSelect}
                        idProp='userId'
                        header={
                            <comp.BtnGroup
                                className='mr-2 my-1'
                                variant='contained'
                                aria-label='Basic button group'
                            >
                                <comp.BTN
                                    onClick={() => setNewMaDialog(true)}
                                    startIcon={<PersonAddIcon />}
                                >
                                    Neuer Benutzer
                                </comp.BTN>
                                <comp.BTN
                                    onClick={() => setPosEditDialog(true)}
                                    startIcon={<EditNoteIcon />}
                                >
                                    Positionen bearbeiten
                                </comp.BTN>
                            </comp.BtnGroup>
                        }
                    />
                )}
            </div>
            <div className='w-full h-full'>
                {data && select && (
                    <EditUser
                        data={data}
                        select={select}
                        fetchUserList={fetchUserList}
                    />
                )}
            </div>
            {data && (
                <AddNewUser
                    newMaDialog={newMaDialog}
                    setNewMaDialog={setNewMaDialog}
                    data={data}
                    fetchUserList={fetchUserList}
                />
            )}
            {data && (
                <PosEdit
                    posEditDialog={posEditDialog}
                    setPosEditDialog={setPosEditDialog}
                    data={data.positionen}
                    fetchUserList={fetchUserList}
                />
            )}
        </div>
    );
};

export default UserManagement;
