import React, {useCallback, useEffect, useMemo, useState} from 'react';
import * as comp from '../components_app/componets';
import * as api from '../components_app/Api';
import {TableCu, TableTreeCu} from '../components_app/Table/Table';
import {useAlert} from '../components_app/useAlert';
import {Box, Typography} from '@mui/material';
import LinearWithValueLabel from '../components_app/progressBar';
import AddIcon from '@mui/icons-material/Add';
import {NeuEza} from './neuEZA';
import _ from 'lodash';
import {useNavigate} from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';

export const EzaManagement = (props) => {
    const showAlert = useAlert();
    const [sachbearbeiter, setSachbearbeiter] = useState([]);
    const [ezas, setEzas] = useState([]);
    const [select, setSelect] = useState(undefined);
    const [ezaInh, setEzaInh] = useState([]);
    const [ezaInhTrigger, setEzaInhTrigger] = useState(false);
    const [neuEzaDialog, setNeuEzaDialog] = useState(false);
    const [userList, setUserList] = useState(undefined);
    const [changes, setChanges] = useState(false);
    const [tblReset, setTblReset] = useState(false);
    const [rapportNrChanged, setRapportNrChanged] = useState(false);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    const [ezaFilter, setEzaFilter] = useState('0');
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [ezasCount, setEzasCount] = useState({
        id1: 0,
        id2: 0,
        id3: 0,
        id4: 0,
        id5: 0,
        id6: 0,
    });

    useEffect(() => {
        if (!user?.rolleId) {
            navigate('/login');
        }
    }, []);

    const ezaStatus0 = [
        {sachbearbeiterId: 1, bez: 'Offen'},
        {sachbearbeiterId: 2, bez: 'Bearbeitung Büro'},
        {sachbearbeiterId: 5, bez: 'Storno'},
    ];
    const ezaStatus1 = [
        {sachbearbeiterId: 1, bez: 'Offen'},
        {sachbearbeiterId: 2, bez: 'Bearbeitung Büro'},
        {sachbearbeiterId: 3, bez: 'Rückmeldung Bosch'},
        {sachbearbeiterId: 5, bez: 'Storno'},
    ];
    const ezaStatus2 = [
        {sachbearbeiterId: 1, bez: 'Offen'},
        {sachbearbeiterId: 2, bez: 'Bearbeitung Büro'},
        {sachbearbeiterId: 3, bez: 'Rückmeldung Bosch'},
        {sachbearbeiterId: 4, bez: 'Abgerechnet'},
        {sachbearbeiterId: 5, bez: 'Storno'},
    ];
    const ezaFilter1 = [
        {sachbearbeiterId: 1, bez: 'Offen (' + ezasCount.id1 + ')'},
    ];
    const ezaFilter2 = [
        {
            sachbearbeiterId: 0,
            bez:
                'Alle (' +
                (ezasCount.id1 +
                    ezasCount.id2 +
                    ezasCount.id3 +
                    ezasCount.id4 +
                    ezasCount.id5) +
                ')',
        },
        {sachbearbeiterId: 1, bez: 'Offen (' + ezasCount.id1 + ')'},
        {sachbearbeiterId: 2, bez: 'Bearbeitung Büro (' + ezasCount.id2 + ')'},
        {sachbearbeiterId: 3, bez: 'Rückmeldung Bosch (' + ezasCount.id3 + ')'},
        {sachbearbeiterId: 4, bez: 'Abgerechnet (' + ezasCount.id4 + ')'},
        {sachbearbeiterId: 5, bez: 'Storno (' + ezasCount.id5 + ')'},
    ];
    //ID   SachbearbeiterSatz sachbearbeiter
    const getBez = (bezId, data, name) => {
        if (!data) {
            return 'Unbekannt';
        }
        const bez = data.find((pos) => pos.sachbearbeiterId === bezId);
        return bez ? bez[name] : 'Unbekannt';
    };

    const formatHours = (value) => {
        // Entfernt alle nicht numerischen Zeichen außer Kommas und Punkte
        let cleanedValue = value.replace(/[^\d.,]/g, '');

        // Ersetzt Kommas durch Punkte, um eine einheitliche Dezimaltrennung zu gewährleisten
        cleanedValue = cleanedValue.replace(/,/g, '.');

        // Wenn das formatierte Ergebnis leer ist oder nur einen Punkt enthält, wird 0.00 zurückgegeben
        if (cleanedValue === '' || cleanedValue === '.') {
            return '0,00';
        }

        // Überprüft, ob das Format korrekt ist (maximal zwei Dezimalstellen)
        const parts = cleanedValue.split('.');
        if (parts.length === 1) {
            // Ganzzahl ohne Dezimalstellen
            return `${parts[0]},00`;
        } else if (parts.length === 2) {
            // Wenn nur eine Ziffer nach dem Punkt steht, füge eine Null hinzu
            if (parts[1].length === 1) {
                return `${parts[0]},${parts[1]}0`;
            } else if (parts[1].length === 2) {
                return `${parts[0]},${parts[1]}`;
            } else {
                // Falls mehr als zwei Ziffern nach dem Punkt stehen, schneide die zusätzlichen Ziffern ab
                return `${parts[0]},${parts[1].substring(0, 2)}`;
            }
        } else {
            // Falls mehr als ein Punkt vorhanden ist, wird 0.00 zurückgegeben
            return '0,00';
        }
    };

    const updateEzaInh = (ezaInh, userList) => {
        if (ezaInh?.length === 0) {
            setSelect((prevState) => ({
                ...prevState,
                istEur: '0',
            }));
            setEzas((prevEzas) =>
                prevEzas.map((eza) =>
                    eza.ezaId === select?.ezaId
                        ? {
                              ...eza,
                              istEur: 0,
                              progress: 0,
                              budget: {...eza.budget, ist: 0},
                          }
                        : eza,
                ),
            );
            updateEzaChanges({istEur: '0'});
            return;
        } else {
            ezaInh.forEach((row) => {
                row.subRows &&
                    row.subRows?.forEach((subRow) => {
                        // Formatierung der Stunden
                        subRow.stunden = formatHours(subRow.stunden);

                        const user = userList?.users?.find(
                            (user) =>
                                `${user.vorname}, ${user.nachname}` ===
                                subRow.name,
                        );
                        if (user) {
                            const position = userList?.positionen?.find(
                                (pos) => pos.positionId === user.positionId,
                            );
                            if (position) {
                                const betrag =
                                    parseFloat(
                                        subRow.stunden.replace(',', '.'),
                                    ) * position.eurstd;
                                subRow.betrag = `${betrag.toFixed(2)} €`;
                                subRow.betragVal = betrag;
                            }
                        }
                    });

                // Aktualisierung des Betrags für die Hauptzeile
                const totalBetrag = row.subRows
                    ? row.subRows.reduce(
                          (sum, subRow) => sum + subRow.betragVal,
                          0,
                      )
                    : 0;
                row.betrag = `${totalBetrag.toFixed(2)} €`;
                row.betragVal = totalBetrag;
            });
            const updateIstEurEza = updateIstEur(
                ezaInh
                    .reduce((sum, subRow) => sum + subRow.betragVal, 0)
                    .toFixed(2),
            );
            setEzas(updateIstEurEza);
            setSelect((prevState) => ({
                ...prevState,
                istEur: updateIstEurEza.find(
                    (eza) => eza.ezaId === select?.ezaId,
                ).istEur,
            }));
        }
    };

    const updateIstEur = (newIstEur) => {
        return ezas?.map((eza) => {
            if (eza.ezaId === select?.ezaId) {
                return {
                    ...eza,
                    istEur: newIstEur,
                    progress: (newIstEur / eza.maxEur) * 100,
                    budget: {
                        ...eza.budget,
                        ist: newIstEur,
                    },
                };
            }
            return eza;
        });
    };
    useEffect(() => {
        console.log('ezas:', ezas);
        let id1 = 0;
        let id2 = 0;
        let id3 = 0;
        let id4 = 0;
        let id5 = 0;
        let id6 = 0;
        ezas.map((eza) => {
            if (eza.status === 1) {
                id1++;
            }
            if (eza.status === 2) {
                id2++;
            }
            if (eza.status === 3) {
                id3++;
            }
            if (eza.status === 4) {
                id4++;
            }
            if (eza.status === 5) {
                id5++;
            }
            if (eza.status === 6) {
                id6++;
            }
        });
        setEzasCount({
            id1: id1,
            id2: id2,
            id3: id3,
            id4: id4,
            id5: id5,
            id6: id6,
        });
    }, [ezas]);
    useEffect(() => {
        if (ezaInh && userList) {
            const newEzaInh = [...ezaInh];
            updateEzaInh(newEzaInh, userList);
            setEzaInh(newEzaInh);
        }
    }, [ezaInhTrigger]);

    const holeData = () => {
        api.HoleEzaData()
            .then((response) => {
                // setData(response?.data);
                response?.data?.ezas &&
                    setEzas(
                        response?.data?.ezas.map((eza) => ({
                            ...eza,
                            sachbearbeiterBez: getBez(
                                eza.sachbearbeiterId,
                                response?.data?.sachbearbeiter,
                                'sachbearbeiter',
                            ),
                            statusBez: getBez(eza.status, ezaStatus2, 'bez'),
                            progress: (eza.istEur / eza.maxEur) * 100,
                            budget: {ist: eza.istEur, max: eza.maxEur},
                        })),
                    );
                response?.data?.sachbearbeiter &&
                    setSachbearbeiter(response?.data?.sachbearbeiter);
            })
            .catch((error) => {
                console.error('Error updating user', error);
                if (error?.response?.data === 'Nicht autorisiert') {
                    localStorage.setItem('jwtToken', '');
                    navigate('/login');
                }
                showAlert({
                    message: error.response.data,
                    severity: 'error',
                    timeout: 3000,
                });
            });
    };

    const updateSachbearbeiterBtn = (id) => {
        const SachbearbeiterSatz = sachbearbeiter.find((sb) => {
            return sb.sachbearbeiterId === id;
        });
        // console.log('sachbearbeiter:', SachbearbeiterSatz);
        api.updateSachbearbeiter(SachbearbeiterSatz)
            .then((response) => {
                showAlert({
                    message: response.data,
                    severity: 'success',
                    timeout: 3000,
                });
                setRapportNrChanged(false);
            })
            .catch((error) => {
                console.error('Error updating user', error);
                showAlert({
                    message: error.response?.data || 'Error',
                    severity: 'error',
                    timeout: 3000,
                });
            });
    };

    useEffect(() => {
        select && holeEzaInh(select?.ezaId);
    }, [select?.ezaId]);

    const holeEzaInh = (id) => {
        api.HoleEzaInh(id)
            .then((response) => {
                setEzaInh(response.data);
                setTblReset(!tblReset);
                console.log('response.data:', response.data);
            })
            .catch((error) => {
                console.error('Error updating user', error);
                showAlert({
                    message: error.response.data,
                    severity: 'error',
                    timeout: 3000,
                });
            });
    };

    useEffect(() => {
        //console.log('user:', user);
        api.HoleUserList(setUserList);
        holeData();
    }, []);

    useEffect(() => {
        //console.log('user:', user);
        api.HoleUserList(setUserList);
        holeData();
        setEzaInh([]);
        setSelect(undefined);
        setTblReset(!tblReset);
    }, [refreshTrigger]);

    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorKey: 'ezaId',
                header: 'ID',
                size: '20', //medium column
            },
            {
                accessorKey: 'ezaNr',
                header: 'EZA-Nr.',
                size: '50', //medium column
            },
            {
                accessorKey: 'sachbearbeiterBez',
                header: 'Sachbearbeiter',
                size: '100', //medium column
            },
            {
                accessorKey: 'statusBez',
                header: 'Status',
                size: '30', //medium column
                Cell: ({cell}) => (
                    <Box
                        component='span'
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() === 'Offen'
                                    ? theme.palette.error.main
                                    : cell.getValue() === 'Bearbeitung Büro'
                                    ? theme.palette.warning.main
                                    : cell.getValue() === 'Rückmeldung Bosch'
                                    ? theme.palette.grey[600]
                                    : cell.getValue() === 'Storno'
                                    ? theme.palette.grey[900]
                                    : theme.palette.success.main,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()?.toLocaleString?.('de-DE', {
                            style: 'currency',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                    </Box>
                ),
            },
            {
                accessorKey: 'progress',
                header: 'Fortschritt',
                size: '60', //medium column
                Cell: ({cell}) => (
                    <Box component='span'>
                        <LinearWithValueLabel progress={cell.getValue()} />
                    </Box>
                ),
            },
            {
                accessorKey: 'budget',
                header: 'Budget',
                size: '60', //medium column
                Cell: ({cell}) => (
                    <Box component='span'>
                        {cell.getValue().ist +
                            '€ / ' +
                            cell.getValue().max +
                            '€'}
                    </Box>
                ),
            },
            {
                accessorKey: 'infoTxt',
                header: 'Info',
                size: '250', //medium column
            },
        ],
        [],
        //end
    );

    const updateEzaChanges = (newData) => {
        const changes = {ezaId: select?.ezaId, ...newData};
        api.updateEza(changes)
            .then((response) => {
                showAlert({
                    message: response.data,
                    severity: 'success',
                    timeout: 3000,
                });
            })
            .catch((error) => {
                console.error('Error updating user', error);
                showAlert({
                    message: error.response?.data || 'Error',
                    severity: 'error',
                    timeout: 3000,
                });
            });
    };

    const ezaStatusEdit = (status) => {
        const save = (value) => {
            setSelect((prevState) => ({
                ...prevState,
                ['status']: value,
            }));

            updateEzaChanges({
                status: Number(value),
            });
            setEzas(
                ezas.map((eza) =>
                    eza.ezaId === select?.ezaId
                        ? {
                              ...eza,
                              statusBez: getBez(
                                  Number(value),
                                  ezaStatus2,
                                  'bez',
                              ),
                          }
                        : eza,
                ),
            );
        };
        //rolleId === 1 = Admin Offen Bearbeitung Warten Abgerechnet Storno
        //user?.rolleId === 2 = Offen Bearbeitung Warten Storno
        //user?.rolleId >= 3 =  Offen Bearbeitung Storno

        if (
            select?.status === '2' &&
            select?.matTxt?.length > '0' &&
            status > '2' &&
            status < '5'
        ) {
            if (
                !window.confirm(
                    'Es sind Materialien erfasst. Diese müssen Manuell der Excel datei hinzugefügt werden!',
                )
            ) {
                return;
            }
        }

        if (user?.rolleId === 1) {
            if (
                window.confirm(
                    'Möchten Sie wirklich den Status des EZA ändern?',
                )
            ) {
                save(status);
            }
        } else if (user?.rolleId === 2) {
            if (status === 4) {
                if (
                    window.confirm(
                        'Möchten Sie wirklich den Status des EZA ändern? Nach der Änderung können nur noch die Rollen Meister oder Admin den EZA sehen und bearbeiten.',
                    )
                ) {
                    save(status);
                }
            } else {
                if (
                    window.confirm(
                        'Möchten Sie wirklich den Status des EZA ändern?',
                    )
                ) {
                    save(status);
                }
            }
        } else {
            if (
                window.confirm(
                    'Möchten Sie wirklich den Status des EZA ändern? Nach der Änderung können nur noch die Rollen Meister oder Admin den EZA sehen und bearbeiten.',
                )
            ) {
                save(status);
            }
        }
        user?.rolleId !== 1 && setRefreshTrigger(!refreshTrigger);
    };

    return (
        <div className='h-full w-full flex-row items-center'>
            <div className='w-full flex space-x-5  overflow-auto'>
                <div className='w-full '>
                    <TableCu
                        columns={columns}
                        data={ezas.filter((eza) => {
                            if (ezaFilter === '0') {
                                return true;
                            } else {
                                return eza.status === Number(ezaFilter);
                            }
                        })}
                        setSelect={setSelect}
                        header={
                            <div>
                                {user?.rolleId === 1 && (
                                    <comp.RadioGroupCu
                                        options={ezaFilter2}
                                        selectedValue={ezaFilter}
                                        setSelectedValue={(e) => {
                                            setEzaFilter(e.target.value);
                                        }}
                                    />
                                )}
                                <comp.BtnGroup
                                    className='ml-10 mr-2 my-1'
                                    variant='contained'
                                    aria-label='Basic button group'
                                >
                                    <comp.BTN
                                        onClick={() => setNeuEzaDialog(true)}
                                        startIcon={<AddIcon />}
                                    >
                                        Neuer EZA
                                    </comp.BTN>
                                </comp.BtnGroup>
                            </div>
                        }
                        idProp='ezaId'
                        Height='350px'
                    />
                    <NeuEza
                        neuEzaDialog={neuEzaDialog}
                        setNeuEzaDialog={setNeuEzaDialog}
                        sachbearbeiter={sachbearbeiter}
                        fetchEzaList={holeData}
                    />
                </div>
            </div>
            <div className='w-full flex mt-5'>
                <div className='w-full'>
                    <TableTreeCu
                        data={ezaInh}
                        setData={setEzaInh}
                        ezaStatus={ezaStatus2}
                        holeEzaInh={holeEzaInh}
                        refreshTrigger={refreshTrigger}
                        setRefreshTrigger={setRefreshTrigger}
                        header={
                            select && (
                                <div className='flex my-2'>
                                    <div className='flex border-2 border-black rounded-full justify-center items-center mx-1'>
                                        <comp.RadioGroupCu
                                            options={
                                                user?.rolleId === 1
                                                    ? ezaStatus2
                                                    : ezaStatus0
                                            }
                                            selectedValue={String(
                                                select.status,
                                            )}
                                            setSelectedValue={(e) => {
                                                ezaStatusEdit(e.target.value);
                                            }}
                                        />
                                    </div>
                                    {select.status > 1 && (
                                        <div className=' flex justify-center items-center space-x-1'>
                                            <comp.TextFieldCu
                                                label='Letzte Rapport Nummer'
                                                type='number'
                                                //text mittig
                                                sx={{
                                                    width: '180px',
                                                    textAlign: 'center',
                                                }}
                                                value={
                                                    sachbearbeiter?.find(
                                                        (sb) => {
                                                            return (
                                                                sb.sachbearbeiterId ===
                                                                select?.sachbearbeiterId
                                                            );
                                                        },
                                                    )?.lfdNr ?? ''
                                                }
                                                onChange={(e) => {
                                                    setRapportNrChanged(true);
                                                    setSachbearbeiter(
                                                        sachbearbeiter.map(
                                                            (sb) =>
                                                                sb.sachbearbeiterId ===
                                                                select?.sachbearbeiterId
                                                                    ? {
                                                                          ...sb,
                                                                          lfdNr: e
                                                                              .target
                                                                              .value,
                                                                      }
                                                                    : sb,
                                                        ),
                                                    );
                                                }}
                                            />
                                            {rapportNrChanged && (
                                                <comp.BTN
                                                    color='green'
                                                    onClick={() =>
                                                        updateSachbearbeiterBtn(
                                                            select.sachbearbeiterId,
                                                        )
                                                    }
                                                >
                                                    <SaveIcon
                                                        sx={{mr: 1, width: 2}}
                                                    />
                                                    Änderungen Speichern
                                                </comp.BTN>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        Height='500px'
                        userList={
                            userList?.users?.map((user) => ({
                                ...user,
                                value: `${user.vorname}, ${user.nachname}`, // Sorgt für einen korrekten Wert
                            })) || []
                        }
                        ezaInhTrigger={ezaInhTrigger}
                        setEzaInhTrigger={setEzaInhTrigger}
                        changes={changes}
                        sachbearbeiter={sachbearbeiter}
                        setSachbearbeiter={setSachbearbeiter}
                        select={select}
                        tblReset={tblReset}
                    />
                </div>
                <div className='flex-row space-y-3'>
                    {select?.ezaNr && (
                        <div className='flex ml-4'>
                            <Typography variant='h6'>
                                {'EZA-Nr.: ' + select.ezaNr}
                            </Typography>
                        </div>
                    )}

                    <div className='flex pl-4 pr-2'>
                        <comp.TextFieldCu
                            value={select?.geb ?? ''}
                            bg_white={true}
                            label='Gebäude'
                            disabled={!select}
                            onChange={(e) => {
                                setChanges(true);
                                setSelect((prevState) => ({
                                    ...prevState,
                                    geb: e.target.value,
                                }));
                            }}
                        />
                        <div className='flex flex-grow mr-2 justify-end'>
                            {changes && (
                                <comp.BTN
                                    color='green'
                                    onClick={() => {
                                        updateEzaChanges({
                                            infoTxt: select.infoTxt,
                                            matTxt: select.matTxt,
                                            arbeitenTxt: select.arbeitenTxt,
                                            geb: select.geb,
                                        });
                                        setChanges(false);
                                        setEzas((prevEzas) =>
                                            prevEzas.map((eza) =>
                                                eza.ezaId === select.ezaId
                                                    ? {
                                                          ...eza,
                                                          infoTxt:
                                                              select.infoTxt,
                                                          matTxt: select.matTxt,
                                                          arbeitenTxt:
                                                              select.arbeitenTxt,
                                                          geb: select.geb,
                                                      }
                                                    : eza,
                                            ),
                                        );
                                    }}
                                >
                                    <SaveIcon sx={{mr: 1}} />
                                    Änderungen Speichern
                                </comp.BTN>
                            )}
                        </div>
                    </div>
                    <comp.MultiLine
                        value={select?.infoTxt ?? ''}
                        label='Infos'
                        disabled={!select}
                        onChange={(e) => {
                            setChanges(true);
                            setSelect((prevState) => ({
                                ...prevState,
                                infoTxt: e.target.value,
                            }));
                        }}
                        rows={3}
                    />
                    <comp.MultiLine
                        value={select?.matTxt ?? ''}
                        label='Material'
                        disabled={!select}
                        onChange={(e) => {
                            setChanges(true);
                            setSelect((prevState) => ({
                                ...prevState,
                                matTxt: e.target.value,
                            }));
                        }}
                        rows={5}
                    />
                    <comp.MultiLine
                        value={select?.arbeitenTxt ?? ''}
                        label='Ausgeführten Arbeiten'
                        disabled={!select}
                        onChange={(e) => {
                            setChanges(true);
                            setSelect((prevState) => ({
                                ...prevState,
                                arbeitenTxt: e.target.value,
                            }));
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
