import {
    MaterialReactTable,
    createRow,
    useMaterialReactTable,
} from 'material-react-table';
import {useEffect, useMemo, useRef, useState} from 'react';
import * as comp from '../componets';
import {Box, IconButton, Popover, Tooltip} from '@mui/material';
import {format} from 'date-fns'; // Importieren Sie die Formatierungsfunktion
import {de} from 'date-fns/locale'; // Importieren Sie die deutsche Lokalisierung
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import {MRT_Localization_DE} from './TableSettings';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import PopupState, {bindTrigger, bindPopover} from 'material-ui-popup-state';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {useAlert} from '../useAlert';
import * as api from '../Api';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import LinearWithValueLabel from '../progressBar';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export const TableCu = (props) => {
    let {
        columns,
        data,
        setSelect,
        enableTopToolbar,
        header,
        Height,
        idProp = 'id',
    } = props;
    if (!Height) {
        Height = '600px';
    }
    const [rowSelection, setRowSelection] = useState({});

    const table = useMaterialReactTable({
        columns,
        data,
        localization: MRT_Localization_DE,
        enableBottomToolbar: false,
        enableTopToolbar: enableTopToolbar,
        enableStickyHeader: true,
        enablePagination: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableHiding: false,
        enableBatchRowSelection: false,
        enableRowSelection: false,
        enableMultiRowSelection: false, //use radio buttons instead of checkboxes
        getRowId: (row) => row[idProp], //give each row a more useful id
        positionToolbarAlertBanner: 'bottom', //move the alert banner to the bottom
        onRowSelectionChange: setRowSelection, //connect internal row selection state to your own
        state: {rowSelection}, //pass our managed row selection state to the table to use

        muiTableContainerProps: {sx: {minHeight: Height, maxHeight: Height}},
        muiTableBodyCellProps: {
            sx: (theme) => ({
                backgroundColor:
                    theme.palette.mode === 'dark'
                        ? theme.palette.grey[900]
                        : theme.palette.grey[50],
            }),
        },
        muiTableBodyRowProps: ({row}) => ({
            onClick: (event) => {
                setRowSelection({[row.id]: true});
                setSelect(data[data.findIndex((d) => d[idProp] === row.id)]);
            },
            sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),

        renderTopToolbarCustomActions: () => (
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                {header}
            </Box>
        ),
        muiTopToolbarProps: {sx: {m: -1}},
    });

    return <MaterialReactTable table={table} />;
};

export const TableTreeCu = (props) => {
    let {
        data,
        Height,
        header,
        userList,
        setData,
        ezaInhTrigger,
        setEzaInhTrigger,
        changes,
        select,
        tblReset,
        idProp = 'id',
        holeEzaInh,
        sachbearbeiter,
        setSachbearbeiter,
        refreshTrigger,
        setRefreshTrigger,
    } = props;
    const [creatingRowIndex, setCreatingRowIndex] = useState();
    const [selectedRowIndex, setSelectedRowIndex] = useState();
    const [value, setValue] = useState();
    const showAlert = useAlert();
    const [disabledDays, setDisabledDays] = useState([]);
    const isDataUpdated = useRef(false);
    const [newDate, setNewDate] = useState();

    useEffect(() => {
        setDisabledDays(data.map((data) => data.datum));
    }, [data]);

    if (!Height) {
        Height = '600px';
    }

    const ezaExport = () => {
        let sb = sachbearbeiter?.find(
            (sb) => sb.sachbearbeiterId === select?.sachbearbeiterId,
        );
        if (window.confirm('Soll die Rapportnummer um 1 erhöht werden?')) {
            sb.lfdNr += 1;
            setSachbearbeiter(
                sachbearbeiter.map((sb) =>
                    sb.sachbearbeiterId === select?.sachbearbeiterId
                        ? {
                              ...sb,
                              lfdNr: sb.lfdNr,
                          }
                        : sb,
                ),
            );
        }
        api.DownloadRapport(select, sb);
    };

    const ezaDelete = () => {
        select?.ezaId > -1 &&
            api
                .deleteEza(select.ezaId)
                .then((response) => {
                    // fetchUserList();
                    showAlert({
                        message: response.data,
                        severity: 'success',
                        timeout: 3000,
                    });
                    setRefreshTrigger(!refreshTrigger);
                })
                .catch((error) => {
                    showAlert({
                        message: error.response?.data || 'Error',
                        severity: 'error',
                        timeout: 3000,
                    });
                });
    };

    const footer = (table) => {
        return (
            select && (
                <div className='flex flex-grow w-full'>
                    <PopupState variant='popover' popupId='demo-popup-popover'>
                        {(popupState) => (
                            <div className='flex w-full space-x-5'>
                                <comp.BTN {...bindTrigger(popupState)}>
                                    <AddCircleOutlineIcon sx={{mr: 1}} /> Neuen
                                    Tag hinzufügen
                                </comp.BTN>
                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <comp.DatePickerCu
                                        setValue={setValue}
                                        value={value}
                                        disabledDays={disabledDays}
                                        onDateSelect={(
                                            selectedDate,
                                            dayOfWeek,
                                        ) => {
                                            setNewDate(selectedDate);
                                            const row = table.getRowCount();
                                            const tmp_data = [
                                                ...data,
                                                {
                                                    betrag: '0.00 €',
                                                    betragVal: 0,
                                                    datum: selectedDate,
                                                    tag: dayOfWeek,
                                                    state: '',
                                                    subRows: [],
                                                },
                                            ];

                                            setData(tmp_data);
                                            isDataUpdated.current = true;
                                            // console.log(
                                            //     'getRowModel',
                                            //     table.getRowModel(),
                                            // );
                                            popupState.close(); // Schließt den Popover
                                        }}
                                    />
                                </Popover>
                                {select.status > 1 && (
                                    <>
                                        <comp.BTN
                                            color='secondary'
                                            onClick={() => ezaExport()}
                                        >
                                            <SystemUpdateAltIcon sx={{mr: 1}} />
                                            EZA Exportieren
                                        </comp.BTN>
                                        <comp.BTN
                                            color='error'
                                            onClick={() => ezaDelete()}
                                        >
                                            <DeleteForeverIcon sx={{mr: 1}} />
                                            EZA Löschen
                                        </comp.BTN>
                                    </>
                                )}
                            </div>
                        )}
                    </PopupState>
                    <div className='w-full flex items-center justify-end grow  pr-5'>
                        {select && (
                            <div className='flex w-[25rem] h-full bg-gray-400/30 rounded-3xl items-center justify-center space-x-5 shadow-2xl pl-5'>
                                <div className='flex-col w-52'>
                                    <LinearWithValueLabel
                                        progress={
                                            select.istEur /
                                            (select.maxEur / 100)
                                        }
                                    />
                                </div>
                                <div className='flex-col w-36'>
                                    {select.istEur +
                                        '€ / ' +
                                        select.maxEur +
                                        '€'}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        );
    };
    const addSubRow = async ({values, table, row}) => {
        // console.log('table', table);
        // console.log('values', values);
        // console.log('row', row);
        // console.log('select', select);

        const OrgRow = data[selectedRowIndex].datum;
        const newEzaInh = {
            ezaId: select.ezaId,
            name: values.name,
            bstSc: values.scbst ? values.scbst : '',
            std: values.stunden,
            dat: data[selectedRowIndex].datum,
        };

        const newEzaInhId = await api
            .addEzaInh(newEzaInh)
            .then((response) => {
                //  console.log('response', response);
                // showAlert({
                //     message: response.data,
                //     severity: 'success',
                //     timeout: 3000,
                // });
                setEzaInhTrigger(!ezaInhTrigger);
                return response.data.ezaInhId;
            })
            .catch((error) => {
                showAlert({
                    message: error.response?.data || 'Error',
                    severity: 'error',
                    timeout: 3000,
                });
                return -1;
            });

        if (selectedRowIndex >= 0) {
            if (!values.name || !values.stunden || values.stunden === '0') {
                // console.log('values', values);
                const txt = !values.name
                    ? 'Bitte ein Mitarbeiter auswählen!'
                    : (!values.stunden || values.stunden === '0') &&
                      'Stunden dürfen nicht 0 sein!';

                showAlert({
                    message: txt,
                    severity: 'error',
                    timeout: 3000,
                });
                return;
            }
            if (data[selectedRowIndex].subRows) {
                //Neu Zeile beim vorhandenen Tag (wichtig)
                values = {
                    ...values,
                    ezaInhId: newEzaInhId,
                    stateNew: true,
                    dat: data[selectedRowIndex].datum,
                };
                data[selectedRowIndex].subRows.push(values);
            } else {
                //Neu Zeile beim neuen Tag (wichtig)

                values = {
                    ...values,
                    ezaInhId: newEzaInhId,
                    stateNew: true,
                    dat: data[selectedRowIndex].datum,
                };
                data[selectedRowIndex].subRows = [values];
            }
        } else {
            //Neuer Tag
            data.push(values);
        }

        setData(data);
        setSelectedRowIndex();
        setCreatingRowIndex();
        table.setCreatingRow(null);
        setEzaInhTrigger(!ezaInhTrigger);
    };
    const updateEzaInhBE = (inh) => {
        // updateEzaInh;
        api.updateEzaInh(inh)
            .then((response) => {
                showAlert({
                    message: response.data,
                    severity: 'success',
                    timeout: 3000,
                });
            })
            .catch((error) => {
                showAlert({
                    message: error.response?.data || 'Error',
                    severity: 'error',
                    timeout: 3000,
                });
            });
    };
    const editSubRow = async ({values, table, row}) => {
        //Edit Zeile (wichtig)
        if (!row.id.includes('.')) {
            console.error('Ungültige row.id:', row.id);
            return;
        }
        // Extrahieren der Haupt- und SubRow-Indizes
        const [mainIndex, subIndex] = row.id
            .split('.')
            .map((part) => parseInt(part, 10));

        // Aktualisieren der Daten
        setData((prevData) => {
            const newData = [...prevData];
            if (newData[mainIndex] && newData[mainIndex].subRows[subIndex]) {
                const subRow = newData[mainIndex].subRows[subIndex];

                // Nur Felder außer EzaInhId überschreiben
                newData[mainIndex].subRows[subIndex] = {
                    ...values,
                    ezaInhId:
                        subRow.ezaInhId !== undefined
                            ? subRow.ezaInhId
                            : values.ezaInhId,
                    std: values.stunden.includes(',')
                        ? values.stunden.replace(',', '.')
                        : values.stunden,
                    stateEdit: true,
                    bstSc: values.scbst,
                };
            } else {
                console.error('Ungültige Indizes:', mainIndex, subIndex);
            }
            updateEzaInhBE(newData[mainIndex].subRows[subIndex]);
            return newData;
        });

        // Bearbeitungszeile schließen und Trigger umschalten
        table.setEditingRow(null);
        setEzaInhTrigger((prev) => !prev);
    };
    const deleteRow = (row) => {
        const {id} = row;
        //Kompletten Tag löschen (wichtig)
        const deleteMainRow = (index) => {
            setData((prevData) => {
                const newData = [...prevData];
                newData.splice(index, 1);
                return newData;
            });

            api.deleteDayEzaInh(select.ezaId, row.original.datum)
                .then((response) => {
                    // fetchUserList();
                    showAlert({
                        message: response.data,
                        severity: 'success',
                        timeout: 3000,
                    });
                })
                .catch((error) => {
                    showAlert({
                        message: error.response?.data || 'Error',
                        severity: 'error',
                        timeout: 3000,
                    });
                });
            setEzaInhTrigger(!ezaInhTrigger);
        };
        //Eine Zeile löschen (wichtig)
        const deleteSubRow = (id, index) => {
            const [mainIndex, subIndex] = index
                .split('.')
                .map((part) => parseInt(part, 10));

            setData((prevData) => {
                const newData = [...prevData];
                if (newData[mainIndex] && newData[mainIndex].subRows) {
                    newData[mainIndex].subRows.splice(subIndex, 1);
                } else {
                    console.error('Ungültige Indizes:', mainIndex, subIndex);
                }
                return newData.filter((row) => row.subRows.length !== 0);
            });

            api.deleteEzaInh(id)
                .then((response) => {
                    // fetchUserList();
                    showAlert({
                        message: response.data,
                        severity: 'success',
                        timeout: 3000,
                    });
                })
                .catch((error) => {
                    showAlert({
                        message: error.response?.data || 'Error',
                        severity: 'error',
                        timeout: 3000,
                    });
                });
        };

        if (id.includes('.')) {
            if (window.confirm('Wollen Sie wirklich diese Zeile löschen?')) {
                deleteSubRow(row.original.ezaInhId, id);
                setEzaInhTrigger(!ezaInhTrigger);
            }
        } else {
            if (
                window.confirm(
                    'Wollen Sie wirklich den kompletten Tag löschen?',
                )
            ) {
                deleteMainRow(id);
                setEzaInhTrigger(!ezaInhTrigger);
            }
        }
    };

    const parseDate = (dateString) => {
        // console.log('dateString', dateString);
        // console.log('data', data);
        if (!dateString) return new Date();
        const [year, month, day] = dateString.split('.').map(Number);
        return new Date(year, month - 1, day);
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'datum',
                header: 'Datum',
                size: '10',
                enableEditing: false,
                Cell: ({cell, row}) => {
                    const dateValue = cell.getValue();
                    const parsedDate = parseDate(dateValue);
                    if (dateValue && !isNaN(parsedDate.getTime())) {
                        return format(parsedDate, 'dd.MM.yyyy', {
                            locale: de,
                        });
                    }

                    return !dateValue ? '' : 'Unbekannt';
                },
            },
            {
                accessorKey: 'tag',
                header: 'Wochentag',
                size: '50',
                enableEditing: false,
            },
            {
                accessorKey: 'name',
                header: 'Name',
                size: '50',
                depth: 1,
                //enableEditing: (row) => row.depth > 0,
                enableEditing: true,
                editVariant: 'select',
                editSelectOptions: userList
                    .filter((user) => user.aktiv)
                    .map((user) => ({
                        value: `${user.vorname}, ${user.nachname}`,
                        key: user.id,
                        label: `${user.vorname}, ${user.nachname}`,
                    })),
                muiEditTextFieldProps: {
                    select: true,
                },
                Cell: ({cell, row}) => {
                    const cellValue = cell.getValue();
                    const parentRow = row.getParentRow();
                    // parentRow &&
                    //     console.log('parentRow', parentRow.original.datum);
                    // parentRow && console.log('row', row);
                    return (
                        <Tooltip title={row.id} arrow>
                            <span>{cellValue}</span>
                        </Tooltip>
                    );
                },
            },
            {
                accessorKey: 'totalHoursForDay',
                header: 'Ges. Std',
                size: '10',
                enableEditing: false,
            },

            {
                accessorKey: 'stunden',
                header: 'Stunden',
                size: '10',
                Edit: ({cell, column, row, table}) => {
                    const [val, setVal] = useState(undefined);
                    const onChange = (e) => {
                        setVal(e.target.value);
                    };
                    const onBlur = (event) => {
                        row._valuesCache[column.id] = event.target.value;
                        if (table.isCreating) {
                            table.setCreatingRow(row);
                        } else if (table.isEditing) {
                            table.setEditingRow(row);
                        }
                    };
                    const getVal = (val) => {
                        let newVal;
                        if (val === undefined) {
                            if (
                                cell.row.original.stunden !== undefined &&
                                cell.row.original.stunden !== ''
                            ) {
                                newVal = parseFloat(
                                    cell.row.original.stunden.replace(',', '.'),
                                );
                            } else {
                                newVal = 0;
                            }
                        } else {
                            newVal = val;
                        }
                        return newVal;
                    };

                    return (
                        <comp.NumInput
                            value={getVal(val)}
                            onChange={onChange}
                            variant='standard'
                            step='0.25'
                            onBlur={onBlur}
                            required
                        />
                    );
                },
            },
            {
                accessorKey: 'scbst',
                header: 'SC/BST',
                size: '50',
                Edit: ({cell, column, row, table}) => {
                    const [val, setVal] = useState(undefined);
                    const onChange = (e) => {
                        setVal(e.target.value);
                    };
                    const onBlur = (event) => {
                        row._valuesCache[column.id] = event.target.value;
                        if (table.isCreating) {
                            table.setCreatingRow(row);
                        } else if (table.isEditing) {
                            table.setEditingRow(row);
                        }
                    };

                    return row.depth > 0 ? (
                        <comp.TextFieldCu
                            value={val ? val : cell.row.original.scbst}
                            onChange={onChange}
                            variant='standard'
                            onBlur={onBlur}
                            required
                        />
                    ) : null;
                },
            },
            {
                accessorKey: 'betrag',
                header: 'Betrag',
                size: '50',
                enableEditing: false,
            },
            {
                accessorKey: 'EzaInhId',
                header: 'EzaInhId',
                enableEditing: false,
            },
            {
                accessorKey: 'userId',
                header: 'userId',
                enableEditing: true,
            },
        ],
        [userList],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        defaultColumn: {
            minSize: 5,
            maxSize: 10,
            size: 10,
        },
        enableEditing: true,
        editDisplayMode: 'row',
        enableGlobalFilter: false,
        enableColumnFilters: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableBottomToolbar: true,
        enableHiding: false,
        enableBatchRowSelection: false,
        localization: MRT_Localization_DE,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableRowSelection: false,
        enableMultiRowSelection: false,
        enableExpanding: true,
        filterFromLeafRows: true,
        getSubRows: (row) => row.subRows,
        initialState: {
            expanded: false,
            columnVisibility: {EzaInhId: false, userId: false},
            sorting: [
                {
                    id: 'datum',
                    desc: true,
                },
            ],
        },
        muiTableContainerProps: {sx: {minHeight: Height, maxHeight: Height}},
        muiTableHeadCellProps: {
            sx: {
                borderRight: '1px solid rgba(224, 224, 224, 1)',
                '&:last-child': {
                    borderRight: 0,
                },
            },
        },
        muiTableBodyRowProps: ({row}) => ({
            onClick: (event) => {
                //  console.log(row);
                event.target.cellIndex && row.toggleExpanded();
            },
            sx: {
                cursor: 'pointer',
                backgroundColor: row.id.includes('.')
                    ? 'inherit'
                    : row.id === 'mrt-row-create'
                    ? 'inherit'
                    : 'lightgrey',
            },
        }),
        renderTopToolbarCustomActions: () => (
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {header}
            </Box>
        ),
        renderBottomToolbarCustomActions: ({table}) => (
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'flex-center',
                }}
            >
                {footer(table)}
            </Box>
        ),
        muiTopToolbarProps: {sx: {m: -1}},
        muiTablePaginationProps: {
            labelDisplayedRows: () => null,
            labelRowsPerPage: () => null,
            rowsPerPageOptions: [],
        },
        enablePagination: false,
        muiPaginationProps: {
            showFirstButton: false,
            showLastButton: false,
        },
        paginateExpandedRows: true,
        enableExpandAll: true,
        createDisplayMode: 'row',
        renderRowActions: ({row, staticRowIndex, table}) => {
            const handleHoverStyles = {
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)', // dunklere Hintergrundfarbe beim Hover
                },
            };
            const handleHoverStylesSub = {
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // dunklere Hintergrundfarbe beim Hover
                },
            };
            if (row.id.includes('.')) {
                return (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <Tooltip title='Zeile bearbeiten'>
                            <IconButton
                                onClick={() => table.setEditingRow(row)}
                                sx={handleHoverStylesSub}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Diese Zeile löschen'>
                            <IconButton
                                color='error'
                                onClick={() => deleteRow(row)}
                                sx={handleHoverStylesSub}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            } else if (!row.id.includes('.')) {
                return (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <Tooltip title='Zeile hinzufügen'>
                            <IconButton
                                sx={handleHoverStyles}
                                onClick={() => {
                                    setCreatingRowIndex(
                                        (staticRowIndex || 0) + 1,
                                    );
                                    setSelectedRowIndex(row.id);
                                    table.setExpanded((prev) => ({
                                        ...prev,
                                        [row.id]: true,
                                    }));
                                    table.setCreatingRow(
                                        createRow(
                                            table,
                                            {
                                                id: null,
                                                firstName: '',
                                                lastName: '',
                                                city: '',
                                                state: '',
                                                managerId: row.id,
                                                subRows: [],
                                            },
                                            -1,
                                            row.depth + 1,
                                        ),
                                    );
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Kompletten Tag löschen'>
                            <IconButton
                                sx={handleHoverStyles}
                                color='error'
                                onClick={() => deleteRow(row)}
                            >
                                <DeleteSweepIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            }
            return null;
        },
        onEditingRowSave: editSubRow,
        onCreatingRowSave: addSubRow,
        onCreatingRowCancel: () => {
            setCreatingRowIndex();
            setSelectedRowIndex();
            const newData = [...data].filter((row) => row.subRows?.length > 0);

            // console.log('data', data);
            setData(newData);
        },

        positionCreatingRow: creatingRowIndex,
    });

    useEffect(() => {
        if (isDataUpdated.current) {
            //  console.log('getRowModel', table.getRowModel());
            isDataUpdated.current = false;
            const rowCount = table.getRowCount();
            const {rows} = table.getRowModel();
            const rowViewIndex = rows.findIndex(
                (row) => row.original?.datum === newDate,
            );

            setCreatingRowIndex(rowViewIndex + 1);
            table.setExpanded((prev) => ({
                ...prev,
                [String(rowCount)]: true,
            }));
            table.setCreatingRow(
                createRow(
                    table,
                    {
                        id: null,
                        firstName: '',
                        lastName: '',
                        city: '',
                        state: '',
                        managerId: String(rowCount),
                        depth: 1,
                    },
                    -1,
                    1,
                ),
            );
            table.setExpanded((prev) => ({
                ...prev,
                [rowCount - 1]: true,
            }));
            setSelectedRowIndex(data.findIndex((d) => d.datum === newDate));
        }
    }, [data]);

    useEffect(() => {
        table.reset();
    }, [tblReset]);

    return <MaterialReactTable table={table} />;
};
