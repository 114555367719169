import React, {createContext, useState, useContext} from 'react';

// Erstellen Sie den Benutzerkontext
const UserContext = createContext(null);

// Erstellen Sie den Benutzerprovider
export const UserProvider = ({children}) => {
    const [user, setUser] = useState(null);

    return (
        <UserContext.Provider value={{user, setUser}}>
            {children}
        </UserContext.Provider>
    );
};

// Benutzer-Hook zum Verwenden des Benutzerkontexts
export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};
