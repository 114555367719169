import React, {useEffect, useState} from 'react';
import * as comp from '../components_app/componets';
import * as api from '../components_app/Api';
import {DialogC} from '../components_app/Dialog';
import {useAlert} from '../components_app/useAlert';
import {Slider} from '@mui/material';

export const NeuEza = (props) => {
    const {neuEzaDialog, setNeuEzaDialog, sachbearbeiter, fetchEzaList} = props;
    const [eza, setEza] = useState({
        ezaNr: '',
        infoTxt: '',
        status: 1,
        sachbearbeiterString: '',
        maxEur: '100',
    });

    const [value, setValue] = useState(null);
    const showAlert = useAlert();

    const handleAdd = (e) => {
        e.preventDefault();
        if (!eza.ezaNr || !eza.sachbearbeiterString || !eza.maxEur) {
            showAlert({
                titel: 'Fehler',
                message: 'Bitte füllen Sie alle erforderlichen Felder aus.',
                severity: 'error',
                timeout: 3000,
            });
            return;
        }
        api.addEza(eza)
            .then((response) => {
                showAlert({
                    message: response.data,
                    severity: 'success',
                    timeout: 3000,
                });
                setEza({
                    ezaNr: '',
                    infoTxt: '',
                    status: 1,
                    sachbearbeiterString: '',
                    maxEur: '100',
                });
                fetchEzaList();
                setValue('');
            })
            .catch((error) => {
                showAlert({
                    message: error.response?.data || 'Error',
                    severity: 'error',
                    timeout: 3000,
                });
            });
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setEza((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeEur = (e) => {
        const {value} = e.target;
        setEza((prevState) => ({
            ...prevState,
            maxEur: parseFloat(value).toFixed(2),
        }));
    };
    const handleChangeEzaNr = (e) => {
        const {value} = e.target;
        setEza((prevState) => ({
            ...prevState,
            ezaNr: parseInt(value),
        }));
    };

    const handleSliderChange = (event, newValue) => {
        setEza((prevState) => ({
            ...prevState,
            maxEur: parseFloat(newValue).toFixed(2),
        }));
    };

    const valuetext = (value) => `${value}`;

    return (
        <>
            <DialogC
                open={neuEzaDialog}
                setOpen={setNeuEzaDialog}
                fullWidth={true}
                maxWidth='sm'
                Title='Neuen EZA anlegen'
                btn={
                    <comp.BTN disabled={false} onClick={handleAdd}>
                        Speichern
                    </comp.BTN>
                }
            >
                <div className='flex justify-center space-x-8 mt-2'>
                    <div className='h-full  space-y-3'>
                        <div className='flex flex-col items-center space-x-2 space-y-4'>
                            <div className='space-x-4'>
                                <comp.NumInput
                                    value={eza.ezaNr || ''}
                                    label='EZA-Nr'
                                    required
                                    step='1'
                                    sx={{
                                        minWidth: '170px',
                                        maxWidth: '175px',
                                    }}
                                    onChange={handleChangeEzaNr}
                                />
                                <comp.NumInput
                                    value={eza.maxEur || ''}
                                    label='Schätzwert'
                                    required
                                    step='50'
                                    sx={{
                                        minWidth: '100px',
                                        maxWidth: '105px',
                                    }}
                                    onChange={handleChangeEur}
                                />
                            </div>
                            <Slider
                                aria-label='Schätzwert'
                                value={parseFloat(eza.maxEur)}
                                onChange={handleSliderChange}
                                color='primary'
                                valueLabelDisplay='auto'
                                step={50}
                                marks
                                min={0}
                                max={5000}
                                getAriaValueText={valuetext}
                            />
                            <comp.TextFieldCu
                                name='infoTxt'
                                label='Info'
                                onChange={handleChange}
                                value={eza.infoTxt || ''}
                                sx={{
                                    minWidth: '300px',
                                    maxWidth: '500px',
                                }}
                            />

                            <comp.ComboBox
                                options={sachbearbeiter
                                    .map((sachbearbeiter) => ({
                                        ...sachbearbeiter,
                                        label: sachbearbeiter.sachbearbeiter,
                                    }))
                                    .sort((a, b) =>
                                        a.label.localeCompare(b.label),
                                    )}
                                width={300}
                                label={'Sachbearbeiter'}
                                value={value}
                                setValue={setValue}
                                inputValue={eza.sachbearbeiter}
                                setInputValue={(newInputValue) =>
                                    setEza((prevState) => ({
                                        ...prevState,
                                        sachbearbeiterString: newInputValue,
                                    }))
                                }
                                required
                            />
                        </div>
                    </div>
                </div>
            </DialogC>
        </>
    );
};
